.popover {
  background-color: white;
  border-radius: 6px;
  border: var(--color-popover-border);
  box-shadow: var(--elevation-medium);
  /* Min width and height be overwritten by inline properties (computed based on the reference element)
   if used in conjunction with useDropdown */
  min-height: 50px;
  min-width: 50px;
}

.hasArrow {
}
.popover:not(.hasArrow) {
  overflow: hidden;
}

@value z-indices: '../../z-indices.css';
@value backdrop as backdropZIndex from z-indices;

.main {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: backdropZIndex;
}

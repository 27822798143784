.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--navrail-width);
  flex-shrink: 0;
}

.logoContainer svg {
  height: 26px;
  fill: white;
}

.logoContainer.compact svg {
  height: 24px;
}

.accountTooltip {
  display: flex;
  align-items: center;
  font-size: var(--text-s);
  justify-content: space-between;
  gap: var(--space-m);
  padding: var(--space-m);
}

h1 + p.subHeading {
  margin-top: calc(var(--space-s) * -1);
}

.subHeading {
  color: var(--color-sub-heading);
}

.headerContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.actionContainer {
  padding: var(--space-s) var(--space-m);
}

.compact {
  flex-direction: column;
  justify-content: stretch;
}

.headerContainer.compact .actionContainer {
  width: 100%;
}

.subtle {
  color: var(--color-text-subtle);
}

.xxs {
  font-size: var(--text-xxs);
}

.xs {
  font-size: var(--text-xs);
}

.s {
  font-size: var(--text-s);
}

.m {
  font-size: var(--text-m);
}

.l {
  font-size: var(--text-l);
}

.xl {
  font-size: var(--text-xl);
}

.xxl {
  font-size: var(--text-xxl);
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

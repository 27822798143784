.list {
  margin: var(--space-m);
}

.list > li {
  padding: var(--space-s);
  border-bottom: 1px solid var(--color-divider);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
}

.noBottomBorder {
}
.noTopBorder {
}

.list.noBottomBorder > li {
  border-bottom: none;
}

.list:not(.noTopBorder) > li:first-child {
  border-top: 1px solid var(--color-divider);
}

.list > li:hover {
  background-color: var(--cool-gray-95);
}

.listItemContent {
  display: flex;
  gap: var(--space-m);
  justify-content: space-between;
  align-items: center;
}

.actionsContainer {
  flex-grow: 0;
  flex-shrink: 0;
}

@value z-indices: '../../../../z-indices.css';
@value backdrop as backdropZIndex from z-indices;

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: backdropZIndex;
  /* Transparent by default */
  background-color: transparent;
}

.translucent {
  background-color: rgba(255, 255, 255, 0.8);
}

:global(.overBackdrop) {
  /* Position relative is required to apply the z-index directive */
  position: relative;
  z-index: calc(backdropZIndex + 1);
}

.additionalResourcesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
}

.additionalResourcesContainer.horizontal {
  flex-direction: row;
  flex-wrap: wrap;
}

.additionalResourcesContainer.horizontal > * {
  flex: 1 1;
  min-width: 270px;
}

.resourceCard {
  display: flex;
  flex-direction: row;
  color: var(--color-text-primary);
  gap: var(--space-m);
  padding: var(--space-m);
  font-size: var(--text-s);
  transition: all 0.2s ease-in-out;
}

.resourceCard:hover {
  border: 1px solid var(--color-button-primary-border);
  background-color: var(--color-button-secondary-hover-bg);
  transition: all 0.2s ease-in-out;
}

.resourceCard:hover .title {
  color: var(--color-text-link);
  transition: all 0.2s ease-in-out;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.title {
  margin: 0;
  font-size: var(--text-m);
}

.iconContainer svg {
  width: 36px;
  height: auto;
  fill: var(--color-text-link);
}

.button {
  color: var(--color-text-link);
  font-weight: bold;
  font-size: var(--text-xs);
  padding: var(--space-s) 0;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

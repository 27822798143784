.footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--space-m);
}

.container {
  composes: verticalStretchContainer from '../../../../lib.module.css';
  align-items: center;
  flex-grow: 1;
}

.content {
  composes: verticalStretchContainer from '../../../../lib.module.css';
  flex-grow: 1;
  min-width: 400px;
  width: calc(100vw - 2 * var(--space-m));
  max-width: 900px;
}

.content > iframe {
  flex-grow: 1;
}

.versionDetails {
  margin: var(--space-m) 0;
}

.versionDetails h3 {
  margin-bottom: var(--space-s);
}

@value z-indices: '../../../z-indices.css';
@value backdrop from z-indices;

.closeButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: var(--space-s);
  outline: 0;
  position: absolute;
  right: 0;
  margin: 20px;
  z-index: backdrop;
}

@media only screen and (min-width: 768px) {
  .closeButton {
    padding: var(--space-xs);
  }
}

:global(.theme-lettuce) .closeButton {
  fill: var(--cool-gray-60);
}

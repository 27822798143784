@value colors: '../../../colors.css';
@value link, default, subtle, border from colors;

@value m: var(--space-m);

.button {
  align-items: stretch;
  background-color: transparent;
  border: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  outline: none;
  position: absolute;
  top: 0;
}

.button.left {
  left: 0;
  margin-left: m;
}

.button.right {
  margin-right: m;
  right: 0;
}

.left .gradient {
  background: linear-gradient(to right, #fff, rgba(255,255,255,0));
  width: 20px;
}

.right .gradient {
  background: linear-gradient(to right, rgba(255,255,255,0), #fff);
  width: 20px;
}

.iconBody {
  align-items: center;
  background-color: #fff;
  display: flex;
  width: 20px;
}

.right .iconBody {
  justify-content: flex-end;
}

.left .iconBody {
  justify-content: flex-start;
}

.iconBody svg {
  fill: default;
}

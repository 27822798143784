@value colors: '../../../colors.css';
@value disabled as disabledColor, link from colors;

.main {
  align-items: center;
  margin: var(--space-s) 0;
  width: 100%;
}

.label {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.disabled,
.input:disabled,
.radioInput:disabled,
.label:disabled {
  color: disabledColor;
  cursor: default;
}

.inputContainer {
  height: 16px;
  position: relative;
  width: 16px;
  flex-shrink: 0;
  flex-grow: 0;
}

.label .inputContainer {
  margin: 0 var(--space-s);
}

.inputOverlay {
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.inputOverlay.unchecked {
  border: 2px solid #7c8a8a;
  background-color: white;
}

.inputOverlay.checked,
.inputOverlay.indeterminate {
  background-color: link;
  border-color: link;
}

.inputOverlay.disabled {
  border-color: var(--color-text-subtle);
}

.inputOverlay.checked.disabled {
  background-color: var(--color-text-subtle);
}

.radioInput {
  cursor: pointer;
  margin: 0 10px 5px;
}

.radioInputOverlay svg {
  fill: link;
  width: var(--space-m);
}

.radioInputLabelDisabled {
  color: var(--color-text-subtle);
}

.radioInputDisabled svg {
  fill: var(--color-text-subtle);
}

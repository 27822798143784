.col {
  overflow: visible;
}

.gridXS {
  display: flex;
}

@media only screen and (min-width: 480px) {
  .gridS {
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .gridS > .fixedCol {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .gridM {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .gridM > .fixedCol {
    width: 100% !important;
  }
}

@media only screen and (min-width: 992px) {
  .gridL {
    display: flex;
  }
}

@media only screen and (max-width: 991px) {
  .gridL > .fixedCol {
    width: 100% !important;
  }
}

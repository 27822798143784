.footer {
  display: flex;
  padding: var(--space-m);
  justify-content: flex-end;
  gap: var(--space-m);
}

.grid {
  flex-grow: 1;
  composes: maxWidthCenterFullScreenModal from '../lib.module.css';
}

.stretch {
  composes: verticalStretchContainer from '../lib.module.css';
}

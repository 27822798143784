.listContainer {
  border-top: 1px solid var(--teal-vivid-30);
}

.list {
  max-height: calc(90vh - 120px);
  overflow: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.accountItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lhs {
  display: flex;
  align-items: center;
  gap: var(--space-s);
}

.lhs.current {
  color: var(--color-button-text);
}

.lhs.current:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-accent);
}

.rhs {
  font-weight: 500;
  color: var(--color-button-text);
  font-size: var(--text-s);
}

.tag {
  font-size: 1.05rem;
  font-weight: 500;
  background-color: #ffd1d9;
  border-radius: 3px;
  padding: 3px 6px;
  color: #6b4552;
  text-transform: uppercase;
}

.itemHeaderLhs {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
}

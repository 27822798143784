.list {
  margin: 0;
  display: flex;
  gap: 8px;
  background-color: var(--color-list-of-chips-bg);
  align-items: center;
  flex-wrap: wrap;
}

/* Ensure that each chip inside a li is treated as a block. This prevents the line height from "leaking"
onto the li element which consequently includes space around the Chip */
.list li > * {
  display: block;
}

.button {
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.25s,
    color 0.25s,
    border 0.25s,
    fill 0.25s;
}

.button:not(.text) {
  border-radius: var(--border-radius-button);
}

.button:disabled {
  pointer-events: none;
  transition: none;
}

.button svg {
  transition: fill 0.25s;
}

.button.block {
  width: 100%;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-s);
  transform: scale3d(1, 1, 1);
  font-weight: 600;
}

.button:not(.text) .body {
  border-radius: var(--border-radius-button);
  padding: 8px 24px;
  font-size: var(--text-s);
  min-width: 160px;
  height: 36px;
}

.button:focus-visible {
  outline: solid var(--color-chip-outline);
  outline-width: 3px;
}

/* Small buttons are deprecated, but not entirely removed. This CSS should be removed when all instances are deleted. */
.button.S:not(.text) .body {
  padding: 6px 12px;
  height: unset;
}

.primary {
  color: var(--color-button-primary);
  background-color: var(--color-button-primary-bg);
  border: 2px solid var(--color-button-primary-border);
  text-transform: uppercase;
}

.primary:hover {
  background-color: var(--color-button-primary-hover-bg);
  border-color: var(--color-button-primary-hover-border);
}

.primary:active {
  background-color: var(--color-button-primary-hover-bg);
  border-color: var(--color-button-primary-active-border);
}

.primary:disabled {
  background-color: var(--color-button-primary-disabled-bg);
  border-color: var(--color-button-primary-disabled-bg);
}

.primary svg {
  fill: var(--color-button-primary);
}

.secondary {
  color: var(--color-button-secondary);
  background-color: var(--color-button-secondary-bg);
  border: 2px solid var(--color-button-secondary-border);
  text-transform: uppercase;
}

.secondary:hover {
  background-color: var(--color-button-secondary-hover-bg);
  border-color: var(--color-button-secondary-hover-border);
}

.secondary:active {
  background-color: var(--color-button-secondary-hover-bg);
  border-color: var(--color-button-secondary-active-border);
}

.secondary:disabled,
.text:disabled {
  color: var(--color-button-secondary-disabled);
  border-color: var(--color-button-secondary-disabled-border);
}

.secondary svg {
  fill: var(--color-button-secondary);
}

.secondary:disabled svg,
.text:disabled svg {
  fill: var(--color-button-secondary-disabled);
}

.secondary:focus-visible {
  border-color: var(--color-button-secondary-hover-border);
}

.text {
  color: var(--color-button-text);
  background: transparent;
  border: none;
  outline: 0;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
  /* Font size is always S for the text button */
  font-size: var(--text-s);
}

.text:hover {
  color: var(--color-button-text-hover);
}

.text svg {
  fill: var(--color-button-text);
}

.text:hover svg {
  fill: var(--color-button-text-hover);
}

.text:focus-visible {
  border-radius: 1px;
  outline-offset: 3px;
  outline-width: 2px;
}

.button {
  --icon-size: var(--icon-size-secondary);
}

.iconContainer {
  overflow: hidden;
  width: var(--icon-size);
  height: var(--icon-size);
  flex-shrink: 0;
}

.button.iconButton,
.button.toolbarIconButton {
  padding: 0;
  border: none;
  background-color: transparent;
  min-width: 0;
  line-height: 1;
}

.button.iconButton svg {
  fill: var(--color-button-icon);
}

.button.iconButton.red svg {
  fill: var(--color-error);
}

.iconButton:hover svg {
  fill: var(--color-button-icon-hover);
}

.button.iconButton.red:hover svg {
  fill: var(--color-error-light);
}

.button.iconButton:focus-visible {
  outline-width: 2px;
}

.button.iconButton:disabled svg {
  fill: var(--color-button-secondary-disabled);
}

.button.toolbarIconButton {
  padding: 6px;
  border-radius: var(--border-radius-primary);
  --icon-size: var(--icon-size-primary);
}

.button.toolbarIconButton {
  fill: var(--color-button-toolbar-icon);
}

.button.toolbarIconButton.primary {
  padding: 4px;
  margin: 2px;
  background-color: var(--color-accent);
}

.button.toolbarIconButton:hover {
  fill: var(--color-button-icon-hover);
  background-color: var(--color-chip-hover-bg);
}

.button.toolbarIconButton.primary:hover {
  background-color: var(--teal-vivid-40);
}

@value redTextHoverColor: var(--color-error-light);

.redText {
  color: var(--color-error);
}

.redText:hover {
  color: redTextHoverColor;
}

.redText svg {
  fill: var(--color-error);
}

.redText:hover svg {
  fill: redTextHoverColor;
}

.wrapper {
  width: 100%;
}

.wrapper.noPointerEvents {
  pointer-events: none;
}

/* Prevent Safari from displaying a default tooltip for text displayed with an ellipsis */
.wrapper::after {
  content: '';
  display: block;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.heading {
  font-size: var(--text-xl);
}

.text {
  color: var(--color-text-subtle);
  margin: 0 var(--space-m) var(--space-m);
  max-width: 300px;
}

@value colors: '../simpleTableColors.css';
@value borderHover from colors;

.cell {
  padding: 0 var(--space-s);
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.contentAlignLeft {
  justify-content: flex-start;
}

.contentAlignRight {
  justify-content: flex-end;
}

.contentAlignCenter {
  justify-content: center;
}

.actionable:hover {
  box-shadow: inset 0 0 0 1px borderHover;
  cursor: pointer;
}

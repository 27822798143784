.menuItem {
  padding: var(--space-s);
  border-radius: var(--border-radius-primary);
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
}

a.menuItem:hover {
  text-decoration: none;
}

.menuItemContent {
  color: var(--color-menu-item);
  display: flex;
  gap: var(--space-s);
  align-items: center;
  font-weight: var(--font-weight-menu);
  font-size: var(--text-menu);
}

.menuItemContent.split {
  justify-content: space-between;
  gap: var(--space-m);
}

.lhs {
  display: flex;
  gap: var(--space-s);
  flex-shrink: 0;
}

.iconContainer {
  width: 16px;
  display: flex;
  align-items: center;
}

.keyCombo {
  --color-keyboard-combo: var(--color-menu-item-icon);
}

.iconContainer svg {
  fill: var(--color-menu-item-icon);
}

.menuItem:hover svg {
  fill: var(--color-menu-item-focused-icon);
}

.menuItem:hover {
  background-color: var(--color-menu-item-bg-focused);
}

.menuItem:hover .menuItemContent {
  color: var(--color-menu-item-focused);
}

.menuItem:hover .keyCombo {
  --color-keyboard-combo: var(--color-text-subtle);
}

.separator {
  margin: 0;
  border-top: 1px solid var(--color-list-separator);
  /* Avoid cursor flicker when hovering over menu item separator */
  cursor: pointer;
}

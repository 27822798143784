.inactiveItem {
  background-color: white;
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--elevation-very-low);
  outline: 2px solid transparent;
  outline-offset: -2px;
}

.item {
  composes: inactiveItem;
  transition:
    background-color 0.2s ease-in-out,
    outline-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.item.disabled {
  pointer-events: none;
}

.item:not(:disabled),
.item:not(.disabled) {
  cursor: pointer;
}

.item:hover,
.item:focus-visible {
  outline-color: var(--color-button-secondary-hover-border);
}

.item:hover {
  box-shadow: var(--elevation-medium);
  background-color: var(--color-button-secondary-hover-bg);
}

.label {
  font-weight: bold;
  transition: color 0.2s ease-in-out;
}

.description {
  color: var(--color-text-subtle);
  font-size: var(--text-s);
}

.item:hover .label {
  color: var(--color-text-highlight);
}

.item:hover .description {
  color: var(--color-text-primary);
}

.item:active,
.item:disabled,
.item.disabled {
  background-color: var(--cool-gray-95);
  box-shadow: none;
}

.item:active {
  outline-color: var(--cool-gray-40);
}

.item:active .label {
  color: var(--cool-gray-40);
}

.item:disabled {
  outline-color: var(--cool-gray-70);
}

.item:disabled .label,
.item.disabled .label,
.item:disabled .description,
.item.disabled .description {
  color: var(--cool-gray-70);
}

.item.noElevation {
  box-shadow: none;
}

@value height: 58px;

.container {
  height: initial !important;
  min-height: height;
}

@value s: var(--space-s);
@value m: var(--space-m);

.textAreaWithLabel {
  position: relative;
  cursor: text;
  padding: s 0 4px m;
}

.textAreaWithLabel textarea {
  resize: none;
  background-color: transparent;
  border: none;
  outline: 0;
  width: 100%;
  display: block;
  font-size: var(--text-m);
  line-height: 24px;
  margin-top: m;
  padding-bottom: calc(s - 4px);
  padding-right: m;
}

.textAreaWithLabel textarea:disabled {
  /* Remove important after legacy global directives are removed */
  background-color: transparent !important;
}


.hContainer {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
  overflow: hidden;
}

.avatarContainer {
  margin-left: var(--space-xs);
}

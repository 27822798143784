.fieldContainer {
  display: flex;
}

.fileSelectContainer {
  position: relative;
  overflow: hidden;
}

.fileSelectContainer .button {
  pointer-events: none;
}

@value hideLeftBy: -100px;
.fileSelectContainer input {
  opacity: 0;
  position: absolute;
  top: 0;
  /* Hide the button on the native file input to prevent the cursor from switching to default on mouse hover   */
  left: hideLeftBy;
  height: 100%;
  width: calc(100% - hideLeftBy);
  z-index: 1;
  cursor: pointer;
}

.removeButton {
  border: 0;
  background: transparent;
  cursor: pointer;
  color: var(--color-primary);
}

.removeButton svg {
  fill: var(--color-primary);
}

.removeButton {
  display: flex;
  align-items: center;
  gap: 5px;
}

.removeButton:hover {
  opacity: 0.9;
}

.selection {
  overflow: hidden;
}

.focusVisible {
  outline: auto var(--color-button-outline);
}

@value m: var(--space-m);
@value s: var(--space-s);

.h2 {
  font-size: var(--text-l);
  font-weight: 400;
  margin-bottom: 0;
}

.fieldset {
  margin: 0;
  border: 0;
}

.legend {
  color: var(--color-text-subtle);
  margin: 0 m s;
}

.children {
  margin: 0 m;
}
.children.column {
  flex-flow: column wrap;
}

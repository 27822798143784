@value colors: '../../../colors.css';
@value disabled from colors;

@value buttonHeight: 22px;
@value buttonTravel: calc(buttonHeight * .64);
@value buttonWidth: calc(buttonHeight + buttonTravel);
@value colorUnchecked: rgb(176, 176, 176);
@value colorChecked: #00bb7e;

.container {
  align-items: center;
  display: flex;
}

.stacked {
  align-items: flex-end;
  flex-direction: column;
}

.button {
  background-color: colorUnchecked;
  border-color: colorUnchecked;
  border-radius: buttonHeight;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  height: buttonHeight;
  position: relative;
  width: buttonWidth;
}

.button:focus {
  box-shadow: rgb(255, 255, 255) 0 0 0 2px, colorChecked 0 0 0 4px, rgba(255, 255, 255, 0.5) 0 0 0 5px;
  outline: none;
}

.checked {
  background-color: colorChecked;
  border-color: colorChecked;
}

.switch {
  background-color: rgb(255, 255, 255);
  border-color: colorUnchecked;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  height: buttonHeight;
  left: -1px;
  position: absolute;
  top: -1px;
  transform: translate3d(0, 0, 0);
  transition: transform 250ms ease-in-out 0s;
  width: buttonHeight;
}

.checked .switch {
  border-color: colorChecked;
  transform: translate3d(buttonTravel, 0, 0);
}

.label {
  font-size: var(--text-s);
  margin-left: var(--space-s);
}
.stacked .label {
  margin-top: var(--space-s);
}

/* This class doesn't like to just be called "disabled" */
.disabledContainer {
  color: disabled;
}
.disabledContainer button {
  background-color: disabled;
  border-color: disabled;
  cursor: not-allowed;
}
.disabledContainer .switch {
  border-color: disabled;
}

@value colors: '../../../colors.css';
@value default, border, hoverableFocus, subtle, inactive from colors;

@value s: var(--space-s);
@value m: var(--space-m);

.menuItem {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: s m;
  white-space: nowrap;
  outline: 0;
  border: 1px solid transparent;
  border-width: 1px 0;
}

.disabled {
  color: inactive;
  pointer-events: none;
}

.menuItem:focus {
  background-color: hoverableFocus;
  border-style: dotted;
  border-color: subtle;
}

/* Necessary to ensure the box-shadow is the same width as the text */
.selectableMenuItem {
  margin: 0 m;
  padding: s 0;
}

.menuItem.pointerEnabled {
  cursor: pointer;
}

.menuItem.pointerEnabled:hover:not(.disabled) {
  background-color: border;
}

.menuItem.hoverEnabled:hover {
  background-color: border;
}

.menuItem.highlighted {
  background-color: #ffebd3;
  border-radius: 10px;
  box-shadow: inset 0 0 0 5px rgba(255, 173, 73, 0.67), 0 0 15px 5px #ffebd3;
  margin: 0;
  padding: s m;
}

.menuItem.selected {
  box-shadow: inset 0 -2px 0 0 #dadada;
}

.menuHorizontal {
  align-items: center;
  display: flex;
  margin: 0;
}

.menuHorizontal .menuItemButton {
  color: default;
}

.menuHorizontal .menuItemButton:hover {
  text-decoration: none;
}

.menuHorizontal a {
  color: default;
}

.menuHorizontal a:hover {
  text-decoration: none;
}

.menuVertical {
  margin: s 0;
}

.menuVertical .menuItem {
  min-width: 200px;
}

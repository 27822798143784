.container {
  composes: verticalStretchContainer from '../lib.module.css';
  flex-grow: 1;
  margin-right: var(--space-m);
}

.content {
  composes: verticalStretchContainer from '../lib.module.css';
  flex-grow: 1;
}

.content > iframe {
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
}

.rhs {
  font-size: var(--text-s);
  margin: 0 var(--space-m);
  text-align: right;
}

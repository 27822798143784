.dashboardsContainer {
  margin-left: calc(2 * var(--space-l));
}

.listItem {
  display: inline-block;
  margin: var(--space-xs);
}

.listItem > * {
  display: flex;
  gap: 6px;
}

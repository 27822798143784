@value offsetTop: 60px;
@value mediumWidth: 650px;
.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background-color: var(--color-modal-background);
  margin-top: offsetTop;
  max-height: calc(90vh - offsetTop);
  width: mediumWidth;
  overflow-y: auto;
  min-height: 0 !important;
}

.container :global {
  animation:
    fade-in 0.08s ease-out,
    scale-out 0.08s ease-out;
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
}

.container.S {
  width: 500px;
}

.container.XS {
  width: 430px;
}

/* Not necessary, but including for completion */
.container.M {
  width: mediumWidth;
}

.container.L {
  width: 860px;
}

.fixedHeight {
  height: calc(90vh - offsetTop);
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background-color: var(--color-modal-background);
  height: 100vh;
  width: var(--drawer-size);
  overflow-y: auto;
  position: fixed;
  right: 0;
  border-radius: 0 !important;
}

.drawerContainer :global {
  animation: drawer-slide-left 0.2s ease-out;
}

@value scaleFactor: .7;
@value translateDistance: -400px;

.appear,
.enter {
  opacity: 0;
  transform: scale(scaleFactor) translateX(translateDistance);
}

.enter-done {
  opacity: 1;
  transform: scale(1) translateX(0);
}

.exit,
.exitDone {
  opacity: 0;
  transform: scale(scaleFactor) translateX(translateDistance);
}

@value colors: '../../../colors.css';
@value z-indices: '../../../z-indices.css';

@value link from colors;
@value blur as blurZIndex from z-indices;

@value spinnerStroke: var(--color-accent);

.blur {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.7;
  z-index: blurZIndex;
}

.center {
  align-content: center;
  display: flex;
  justify-content: center;
}

.spinner {
  position: relative;
}

.normal {
  height: 80px;
  width: 80px;
}

.small {
  height: 16px;
  width: 16px;
}

.spinner:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.svg {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  margin: auto;
}

.circle {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: spinnerStroke;
  }
  40% {
    stroke: spinnerStroke;
  }
  66% {
    stroke: spinnerStroke;
  }
  80%,
  90% {
    stroke: spinnerStroke;
  }
}

@keyframes color {
  0% {
    stroke: spinnerStroke;
  }
  40% {
    stroke: spinnerStroke;
  }
  66% {
    stroke: spinnerStroke;
  }
  80%,
  90% {
    stroke: spinnerStroke;
  }
}

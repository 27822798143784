@value colors: '../../colors.css';
@value border, entityListItem, crispGreen from colors;

@value m: var(--space-m);
@value s: var(--space-s);

.entityList {
  margin: m;
}

.entityList li {
  display: block;
  margin-bottom: m;
  padding-bottom: m;
  border-bottom: 1px solid border;
}

.entityListItemWithActionsOneLineLi {
  position: relative;
}

li.compact {
  margin-bottom: s;
  padding-bottom: s;
}

.entityListItemWithActionsOneLineGrid {
  position: absolute;
  right: 0;
  top: 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
}

.actions.alignTop {
  align-items: flex-start;
}

.actions.alignCenter {
  align-items: center;
}

.actions > div:not(:last-child) {
  margin-right: m;
}

.actionButton:focus {
  outline: 2px solid crispGreen;
  outline-offset: 2px;
}
.actionButton:focus:not(:focus-visible) {
  outline: none;
}

.heading {
  color: entityListItem;
  font-size: var(--text-s);
  font-weight: bold;
  text-transform: uppercase;
}

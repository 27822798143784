@value colors: '../../ui/SimpleTable/simpleTableColors.css';
@value z-indices: '../../../z-indices.css';

@value border, thBackground, divider from colors;
@value blur as blurZIndex, stickyOverlay as stickyOverlayZIndex from z-indices;

.body {
  position: relative;
  outline: none;
}

.body.inline {
  display: inline-block;
}

.table {
  border-collapse: collapse;
  width: 100%;
  outline: none;
}

.table th {
  position: sticky;
  top: -1px;
  z-index: stickyOverlayZIndex;
}

.table td,
.table th {
  max-width: 300px;
  border: 1px solid border;
  padding: 0;
}

/* Required for eslint */
.textWrap {
}

.table:not(.textWrap) td,
.table:not(.textWrap) th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table td {
  height: 45px;
}

.table th {
  height: 70px;
  background-color: thBackground;
  font-weight: 300;
}
.table th:hover .sortIconContainer svg {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.blur {
  z-index: blurZIndex;
}

.sortIconContainer {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 var(--space-s);
}
.sortIconContainer svg {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.sortIconSorted svg {
  opacity: 1;
}

.card {
  display: flex;
  gap: var(--space-m);
  align-items: center;
  height: 100%;
  padding: var(--space-m);
  justify-content: space-between;
}

.readOnly {
}

.card:not(.readOnly):hover {
  background-color: var(--color-generic-highlight-bg);
}

.lhs {
  display: flex;
  gap: var(--space-m);
  align-items: center;
  max-width: calc(100% - 28px);
  overflow: hidden;
}

.rhs {
  flex-shrink: 0;
}

.header {
  display: flex;
  gap: var(--space-s);
  align-items: center;
}

.displayName {
  font-weight: bold;
  color: var(--color-text-heading);
}

.email {
  margin-top: var(--space-xs);
  white-space: nowrap;
}

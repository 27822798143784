@value markerWidth: 5px;

.notification {
  border: 1px solid var(--highlight-color);
  display: flex;
  gap: var(--space-s);
  padding: var(--space-m);
  border-left-width: markerWidth;
  padding-left: calc(var(--space-m) - markerWidth);
  background-color: var(--background-color);
  margin: var(--space-m);
  font-size: var(--text-s);
  overflow: hidden;
}

.notification.row {
  align-items: center;
}

.iconSection {
  width: var(--icon-size-primary);
  height: var(--icon-size-primary);
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
}

.iconSection > svg {
  fill: var(--highlight-color);
}

.contentSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.notification.row .contentSection {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--space-s);
}

.dismissSection {
  width: var(--icon-size-secondary);
  height: var(--icon-size-secondary);
  flex-shrink: 0;
  flex-grow: 0;
}

.dismissSection button {
  width: var(--icon-size-secondary);
  height: var(--icon-size-secondary);
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dismissSection button:focus-visible {
  outline-color: var(--teal-vivid-60);
}

.success {
  --highlight-color: var(--teal-vivid-20);
  --background-color: #eefbef;
}

.info {
  --highlight-color: var(--color-info);
  --background-color: #f5f8ff;
}

.warning {
  --highlight-color: var(--color-warning);
  --background-color: #fffbea;
}

.failure {
  --highlight-color: var(--color-error);
  --background-color: #fff0f0;
}

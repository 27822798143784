body.theme-unfi {
  --text-xxs: 1rem;
  --text-xs: 1.3rem;
  --text-s: 1.4rem;

  --font-primary: 'Outfit', 'sans-serif';

  /* Spacing */
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 32px;

  --color-error: #f42e48;

  --cool-gray-10: #1f2933;
  --cool-gray-20: #323f4b;
  --cool-gray-30: #3e4c59;
  --cool-gray-40: #52606d;
  --cool-gray-50: #616e7c;
  --cool-gray-60: #7b8794;
  --cool-gray-70: #9aa5b1;
  --cool-gray-80: #e7ebef;
  --cool-gray-90: #eceff4;
  --cool-gray-95: #f5f7fa;

  /** Lettuce Blue **/
  --color-blue-10: #f0f8ff;
  --color-blue-100: #e7f1fb; /*light */
  --color-blue-200: #d3e4f4;
  --color-blue-300: #b2cfe9;
  --color-blue-400: #89b2d9; /* Inactive */
  --color-blue-500: #5e92c4;
  --color-blue-600: #3b73a7; /* Active */
  --color-blue-700: #225483;
  --color-blue-800: #133759; /* Base */
  --color-blue-900: #091c2e; /* Dark */
  --color-blue-600-vivid: #266db2;

  --color-text-primary: var(--cool-gray-40);
  --color-text-secondary: #666666;
  --color-primary: var(--color-blue-600);
  --color-primary-dark: var(--color-blue-800);
  --color-primary-light: var(--color-blue-400);
  --color-primary-lightest: var(--color-blue-10);
  --color-divider: #d9d9d9;
  --color-green-dark: #24671f;
  --color-outline: var(--color-green-dark);
  --color-button-outline: var(--color-outline);
  --color-menu-highlight-translucent: var(--color-blue-10);
  --color-text-link: var(--color-primary);
  --color-container-border: var(--color-divider);
  --color-accent: var(--color-blue-600-vivid);

  /* Colors backgrounds */
  --body-color-background: var(--cool-gray-95);
  --color-background-disabled: #e9e9e9;
  --color-surface: #ffffff;

  /* Colors lists */
  --color-list-item: var(--cool-gray-30);
  --color-list-item-bg-focused: var(--color-menu-highlight-translucent);
  --color-list-item-focused: var(--color-accent);
  --color-list-item-selected: var(--color-accent);
  --color-list-item-bg-selected: var(--cool-gray-95);
  --color-list-divider: rgba(128, 108, 102, 0.12);

  --color-button-text: var(--color-blue-700);
  --color-button-text-hover: var(--color-accent);

  /* Checkbox */
  --color-checkbox-unchecked: var(--color-accent);
  --color-checkbox: var(--color-accent);
  --color-checkbox-checked: var(--color-accent);

  /* Colors tables */
  --color-table-header-border: #ddd;
  --color-table-alternating-row: var(--color-list-divider);

  /* Colors misc */
  --color-progressbar-fill: var(--color-primary-light);
  --color-progressbar-background: var(--color-divider);

  --topnav-height: 0px;

  /* Nav Rail */
  --navrail-color-background: #093e52;
  --navrail-width: 80px;
  --navrail-compact-width: 60px;
  --navrail-menu-item-color-icon: white;
  --navrail-menu-item-color-icon-hover: white;
  --navrail-menu-item-color-hover: #386479;
  --navrail-menu-item-label-color: white;
  --navrail-menu-item-label-color-hover: white;
  --navrail-menu-item-selected-color-icon: white;
  --navrail-menu-item-selected-label-color: white;

  /* Chips */
  --color-chip-bg: white;
  --color-chip-border: var(--cool-gray-80);
  --color-chip-hover: var(--color-green-dark);
  --color-chip-hover-bg: var(--color-menu-highlight-translucent);
  --color-chip-hover-border: var(--color-green-dark);
  --color-chip-selected-border: var(--color-green-dark);
  --color-chip-selected-bg: var(--color-menu-highlight-translucent);
  --color-chip: var(--color-text-secondary);
  --color-chip-selected: var(--color-green-dark);
  --color-chip-icon-fill: var(--cool-gray-60);
  --color-chip-hover-icon-fill: var(--color-green-dark);
  --color-chip-outline: var(--color-green-dark);

  /* Input */
  --color-input-bg: #f0f0f0;
  --color-input-bg-hover: var(--color-menu-highlight-translucent);
  --color-input-focused-bg: var(--color-menu-highlight-translucent);
  --color-input-border: var(--cool-gray-60);
  --color-input-focused-border: var(--teal-vivid-30);
  --color-input-error: var(--color-error);
  --color-input-error-bg: #fff0f0;
  --color-input-focused-label-color: var(--teal-vivid-30);
  --input-icon-fill: var(--cool-gray-60);
  --input-focused-icon-fill: var(--teal-vivid-30);
  --input-height: 58px;
  --legacy-input-height: 48px;

  /* Misc */
  --border-radius-primary: 4px;
  --border-radius-panel: 6px;
  --icon-size-primary: 24px;
  --icon-size-secondary: 18px;
  --icon-size-tertiary: 12px;
  --color-spinner: var(--color-accent);
  --color-panel-neutral-border: var(--cool-gray-90);
  --color-dashboard-notice-bg: var(--color-blue-10);
  --color-dashboard-notice-border: var(--color-blue-500);

  /* Modal */
  --color-modal-background: white;

}

@value colors: '../../colors.css';
@value z-indices: '../../z-indices.css';
@value pageActionBar from colors;
@value pageActionBar as pageActionZIndex from z-indices;

.actionBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: pageActionBar;
  z-index: pageActionZIndex;
}

:global(.theme-lettuce) .actionBar {
  left: var(--navrail-width);
}

.actionBarContent {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.actionBarContent {
  margin: var(--space-s);
}

.actionBarContent > div {
  margin: var(--space-s);
}

.actionsLeft {
  margin-right: auto !important;
}

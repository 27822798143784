@value z-indices: '../../z-indices.css';
@value actionsContainer as actionsContainerZIndex from z-indices;

.container {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: actionsContainerZIndex;
}

.body {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.skipLink {
  background: white;
  position: absolute;
  top: 55px;
  transform: translateX(-200%);
  transition: transform 0.3s;
  outline: 0;
}

.skipLink:focus {
  transform: translateX(0%);
  border-width: 2px;
}

:global(.theme-lettuce) .skipLink {
  top: var(--space-s);
  left: calc(var(--navrail-width) + var(--space-s));
  z-index: 1;
}

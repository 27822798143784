.item {
  overflow: hidden;
  position: relative;
  padding: var(--padding-list-item);
  margin: var(--margin-list-item);
  color: var(--color-list-item);
  font-weight: var(--font-weight-list);
  font-size: var(--text-list);
  border-radius: 4px;
}

.actionable {
  cursor: pointer;
}

.selected {
  color: var(--color-list-item-selected);
  background-color: var(--color-list-item-bg-selected);
}

:focus-visible > .focused {
  background-color: var(--color-list-item-bg-focused);
}

@value z-indices: '../../../../z-indices.css';
@value navrailPopoverMenu from z-indices;

.container {
  background-color: white;
  box-shadow: 1px 1px 7px 0 rgb(0 0 0 / 12%);
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--color-popover-border);
  z-index: navrailPopoverMenu;
}

.container.expansive {
  height: 100vh;
}

.compact .contentWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 400px;
  min-height: 40px;
  /*  TODO slide in animation */
}

@value arrowWidth: 9px;
@value arrowHeight: 7px;

.arrow:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: calc(-1 * arrowWidth);
  left: calc(-2 * arrowHeight);
  width: 0;
  height: 0;
  visibility: visible;
  border-width: arrowWidth arrowHeight arrowWidth arrowHeight;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.backdrop {
  /* Backdrop should not cover the navrail */
  left: var(--navrail-width);
}

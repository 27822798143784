h1,
h2 {
  color: var(--color-text-heading);
  font-weight: 600;
  letter-spacing: 0;
  margin: var(--space-m);
}

.L {
  font-size: 3.2rem;
  line-height: 4rem;
}

.M {
  font-size: 2.8rem;
  line-height: 3.6rem;
}

.S {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

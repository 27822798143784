.body {
  background-color: var(--navrail-color-background);
  width: var(--navrail-width);
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.compact {
  width: var(--navrail-compact-width);
}

.body.space-between {
  justify-content: space-between;
}

.body.center {
  justify-content: center;
}


.userDetails {
  padding: var(--space-s) var(--space-m);
}

.userName {
  font-size: var(--text-l);
  font-weight: 500;
}

.userEmail {
  font-size: var(--text-s);
  color: var(--color-text-subtle);
}

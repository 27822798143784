.beta:after {
  content: 'new';
  text-transform: uppercase;
  color: var(--color-label-text);
  background-color: var(--cool-gray-90);
  font-weight: bold;
  font-size: var(--text-xxs);
  padding: 2px 6px;
  margin-right: -3px;
  border-radius: 12px;
  line-height: normal;
}

li:hover .beta:after {
  color: var(--teal-vivid-30);
  background-color: var(--teal-vivid-90);
}

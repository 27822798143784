.select {
  cursor: pointer;
}

.select svg:last-child {
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.toggled svg:last-child {
  transform: rotate(180deg);
}

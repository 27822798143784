@value colors: '../../../colors.css';
@value disabled, default from colors;

.button {
  border-radius: var(--border-radius-button);
  cursor: pointer;
  position: relative;
}

.body {
  align-items: center;
  display: flex;
  justify-content: center;
}

.iconContainer {
  display: flex;
}

/* Sizes */

.large {
  font-size: var(--text-l);
  font-weight: 400;
  letter-spacing: 0.05rem;
  padding: 12px 24px;
}
.large.icon-hover {
  padding: 12px;
}

.large .iconContainer :first-child {
  height: 19px;
  width: 19px;
}

.medium {
  font-size: var(--text-m);
  font-weight: 400;
  letter-spacing: 0.02rem;
  padding: 9px 22px;
}
.medium.icon-hover {
  padding: 9px;
}

.medium .iconContainer :first-child {
  height: 16px;
  width: 16px;
}

.small {
  font-size: var(--text-s);
  font-weight: 400;
  letter-spacing: 0.02rem;
  padding: 9px 20px;
}
.small.icon-hover {
  padding: 9px;
}

.small .iconContainer :first-child {
  height: 14px;
  width: 14px;
}

/* Styles */

.border {
  background-color: transparent;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.light-border {
  background-color: transparent;
  /* This is `border` from colors but it causes issues if I import it */
  border: 1px solid #ebe8e3;
  color: default;
}

.icon-hover {
  background-color: transparent;
  border: none;
  color: default;
}

.light-border:hover,
.icon-hover:hover {
  background-color: #ebe8e3;
}
.light-border .iconContainer :first-child,
.icon-hover .iconContainer :first-child {
  fill: default;
}

.border .iconContainer :first-child {
  fill: var(--color-primary);
}

.borderDisabled,
.light-borderDisabled,
.light-borderDisabled:hover {
  border-color: #cbd2d5;
  background-color: #f9fafc;
  color: disabled;
}

.borderDisabled .iconContainer :first-child,
.light-borderDisabled .iconContainer :first-child {
  fill: disabled;
}

.href {
  background-color: transparent;
  border: 0;
  color: var(--color-text-link);
  outline: 0; /* TODO handle this better for focus with keyboard navigation */
  padding: 0;
  text-decoration: none;
}

.href:hover {
  text-decoration: underline;
}

.href .iconContainer :first-child {
  fill: var(--color-text-link);
}

.hrefDisabled,
.hrefDisabled:hover,
.icon-hoverDisabled,
.icon-hoverDisabled:hover {
  background-color: transparent;
  color: disabled;
  text-decoration: none;
}

.hrefDisabled .iconContainer :first-child,
.icon-hoverDisabled .iconContainer :first-child {
  fill: disabled;
}

.emphasis {
  background-color: #eb541f;
  border: 1px solid #eb541f;
  color: #fff;
}

.primary {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: #fff;
}

.emphasis .iconContainer :first-child,
.primary .iconContainer :first-child {
  fill: #fff;
}

.emphasisDisabled,
.primaryDisabled {
  background-color: #c1c1c1;
  border-color: #c1c1c1;
  color: #fff;
}

.emphasisDisabled .iconContainer :first-child,
.primaryDisabled .iconContainer :first-child {
  fill: #fff;
}

.link-default-text-color {
  background-color: transparent;
  border: 0;
  color: default;
  outline: 0; /* TODO handle this better for focus with keyboard navigation */
  padding: 0;
  text-decoration: none;
}

.href:focus-visible {
  text-decoration: underline;
}

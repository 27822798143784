.item {
  height: var(--navrail-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
  cursor: pointer;
}

.compact {
  height: var(--navrail-compact-width);
}

button.item {
  width: 100%;
  background-color: transparent;
  border: none;
}

.iconContainer {
  pointer-events: none;
  display: flex;
  align-items: center;
}

.iconContainer svg {
  width: 24px;
  fill: var(--navrail-menu-item-color-icon);
}

.label {
  font-size: 1.2rem;
  color: var(--navrail-menu-item-label-color);
  text-align: center;
  font-weight: 500;
  padding: 0 var(--space-xs);
  pointer-events: none;
}

.item.selected {
  background-color: rgba(255, 255, 255, 0.08);
}

.item.focused {
  background-color: rgba(0, 0, 0, 0.15);
}

.item:hover {
  background-color: var(--navrail-menu-item-color-hover);
}

.item:hover .label {
  color: var(--navrail-menu-item-label-color-hover);
}

.item:hover svg {
  fill: var(--navrail-menu-item-color-icon-hover);
}

.item.selected .label {
  color: var(--navrail-menu-item-selected-label-color);
}

.item.selected .iconContainer svg {
  fill: var(--navrail-menu-item-selected-color-icon);
}

@value m: var(--space-m);
@value s: var(--space-s);

.title {
  font-weight: 600;
  letter-spacing: 0.02rem;
  color: var(--color-text-heading);
  margin: m m s m;
}

.L {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.M {
  font-size: 1.6rem;
  line-height: 2rem;
}

.S {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
}

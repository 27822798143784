.stretchContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@value iconSize: 100px;
.body svg {
  max-width: iconSize;
  max-height: iconSize;
}

.button {
  width: var(--icon-size-primary);
  height: var(--icon-size-primary);
  padding: 0;
  display: block;
  background: transparent;
  border: none;
  overflow: hidden;
  cursor: pointer;
  flex-shrink: 0;
}

.button svg {
  fill: var(--cool-gray-60);
  transition: fill 0.25s ease-in-out;
}

.button:hover svg {
  fill: var(--cool-gray-40);
}

@value s: var(--space-s);

.infobar {
  border-radius: 4px;
  margin: var(--space-m);
  padding: s;
  position: relative;
}

.grid {
  align-items: center;
}

.children {
  padding: 7px s;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .children {
    text-align: left;
  }
}

.dismissCol, .actionCol {
  margin-left: auto;
  padding: 7px s;
  text-align: center;
}

.neutral {
  background-color: var(--color-background-neutral);
}

.positive {
  background-color: var(--color-background-positive);
}

.warning {
  background-color: var(--color-background-warning);
}

@value keyboardCombo: var(--color-keyboard-combo);

.container {
  color: keyboardCombo;
}

.container kbd {
  border-radius: 3px;
  border: 1px solid keyboardCombo;
  padding: 2px 7px;
  font-size: var(--text-m);
}

.container span {
  margin: 0 4px;
}

.container.size-s kbd {
  padding: 2px 5px;
  font-size: var(--text-xs);
}

:global(.theme-lettuce) .container kbd {
  font-family: var(--font-mono);
  padding: 2px 5px;
  font-size: var(--text-xs);
}

:global(.theme-lettuce) .container.size-s kbd {
  font-family: var(--font-mono);
  padding: 1px 4px;
  font-size: var(--text-xxs);
}

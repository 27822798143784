.forbidden {
  margin: 0;
}

@media only screen and (min-width: 1240px) {
  .forbidden {
    margin: 0 var(--space-m);
  }
}

.heading {
  color: var(--color-text-subtle);
  font-weight: 400;
  -webkit-font-smoothing: auto;
}

.searchField {
  display: flex;
  align-items: center;
  gap: var(--space-s);
  padding: var(--space-xs) var(--space-s);
  border: 1px solid var(--color-chip-border);
  border-radius: 100px;
  background-color: var(--color-chip-bg);
  height: calc(var(--space-l) + 2px);
  min-width: 275px;
  transition: border 0.2s ease-in;
}

.searchField button:focus-visible {
  border-radius: 50%;
  outline: auto var(--color-button-outline);
  outline-offset: 2px;
}

.searchField button svg {
  fill: var(--color-chip-icon-fill);
}

.searchField button:focus-visible svg,
.searchField button:hover svg {
  fill: var(--color-button-primary-hover-bg);
}

.searchField:hover,
.searchField:focus-within {
  border-color: var(--color-chip-hover);
}

.searchField:focus-within {
  background-color: var(--color-chip-selected-bg);
  border-color: var(--color-chip-selected-border);
}

.searchInput {
  border: none;
  background-color: unset;
  flex-grow: 1;
  font-size: var(--text-s);
  line-height: 1.8em;
}
.searchInput:focus {
  outline: none;
}

@value iconSize: var(--icon-size-secondary);

.searchField svg.icon {
  fill: var(--color-chip-icon-fill);
  height: iconSize;
  width: iconSize;
  position: relative;
  pointer-events: none;
}

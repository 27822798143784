.container {
  border-bottom: 1px solid var(--color-divider);
}

.headline {
  display: flex;
  gap: var(--space-s);
  padding-right: var(--space-m);
  justify-content: space-between;
  align-items: center;
}


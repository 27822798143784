@value colors: '../../../colors.css';
@value border, hoverableFocus from colors;

.dropdown {
  position: relative;
}

.button {
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
  cursor: pointer;
  font-size: var(--text-s);
  font-weight: 400;
  padding: 9px 22px;
  width: 100%;
  white-space: nowrap;
}
.button > img,
.button > svg {
  display: block;
  stroke: #aaa;
  stroke-width: 2px;
}

.arrow {
  margin-left: 6px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

/* Button Styles */

.iconHover {
  padding: s;
  border-radius: var(--border-radius-button);
}

.bordered {
  background-color: transparent;
  border: 1px solid border;
  border-radius: var(--border-radius-button);
}

.bordered:hover,
.iconHover.open,
.iconHover:hover {
  background-color: border;
}

.flat,
.icon,
.iconHover {
  border: 0;
  outline: 0; /* TODO handle this better for focus with keyboard navigation */
}

.flat:focus,
.icon:focus,
.iconHover:focus {
  background-color: hoverableFocus;
}

.flat:focus {
  outline: 3px solid hoverableFocus;
}

.flat {
  -webkit-font-smoothing: auto;
  align-items: center;
  display: flex;
  font-size: var(--text-m);
  padding: 0;
  width: auto;
}

.icon {
  padding: 0;
}

.icon img {
  display: block;
}

.spinner {
  position: absolute;
  left: 6px;
}

button.buttonWithArrow {
  border-style: solid;
  border-width: 1px;
  padding: 0 var(--space-s) 0 var(--space-s);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowRight {
  margin-left: 6px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  fill: var(--docs-icon-color-neutral);
}

.item {
  display: flex;
  gap: var(--space-m);
  justify-content: space-between;
  align-items: center;
  padding: var(--space-s) var(--space-m);
}

.item:hover {
  background-color: var(--color-list-item-bg-soft-focus);
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--color-container-border);
}

.rhs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.publishedBy {
  color: var(--color-text-subtle);
  font-size: var(--text-s);
}

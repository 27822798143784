.panel {
  text-align: center;
  padding: var(--space-m) var(--space-s);
  width:462px;
}

.panel input {
  min-width: 400px;
  width:100%;
}

.panel div[class*=supportingText] {
  text-align: left;
}
.container {
  margin: var(--space-m) 0;
}

.label {
  color: var(--color-text-heading);
  font-weight: 600;
  font-size: var(--text-m);
}

.description {
  color: var(--color-text-subtle);
  font-size: var(--text-s);
}


body.theme-dot {
  --color-primary-10: #15314c;
  --color-primary-20: #1C4C78;
  --color-primary-30: #1A66AE;
  --color-primary-40: #1E81DE;
  --color-primary-50: #4C9BE5;
  --color-primary-60: #98C2EA;
  --color-primary-80: #C3DCF4;
  --color-primary-95: #EFF6FD;

  --color-secondary: #2E99FF;

  --color-accent: var(--color-primary-30);
  --color-highlight: var(--color-primary-95);
  --color-text-highlight: var(--color-secondary);
  --color-text-link: var(--color-accent);

  /* Misc */
  --color-outline-base: var(--blue-vivid-100);

  /*--color-secondary: #FAC13A;*/

  /* Buttons */
  --color-button-outline: var(--color-outline-base);
  --color-button-primary: white;
  --color-button-primary-bg: var(--color-accent);
  --color-button-primary-border: var(--color-accent);
  --color-button-primary-hover-bg: var(--color-primary-40);
  --color-button-primary-hover-border: var(--color-primary-40);
  --color-button-primary-active-border: var(--color-primary-60);
  --color-button-primary-disabled-bg: var(--cool-gray-60);

  --color-button-secondary: var(--color-accent);
  --color-button-secondary-bg: white;
  --color-button-secondary-border: var(--cool-gray-80);
  --color-button-secondary-hover-bg: var(--color-highlight);
  --color-button-secondary-hover-border: var(--color-primary-40);
  --color-button-secondary-active-bg: white;
  --color-button-secondary-active-border: var(--color-accent);
  --color-button-secondary-disabled: var(--cool-gray-60);
  --color-button-secondary-disabled-border: var(--cool-gray-80);

  --color-button-text: var(--color-primary-30);
  --color-button-text-hover: var(--color-accent);
  --color-button-icon-hover: var(--color-primary-40);

  /* Navrail */
  --navrail-color-background: var(--color-primary-10);
  --navrail-menu-item-color-icon: var(--color-primary-80);
  --navrail-menu-item-color-icon-hover: var(--color-primary-80);
  --navrail-menu-item-color-hover: var(--color-accent);
  --navrail-menu-item-label-color: var(--color-primary-60);
  --navrail-menu-item-label-color-hover: white;
  --navrail-menu-item-selected-color-icon: white;
  --navrail-menu-item-selected-label-color: white;

  /* Badge */
  --color-badge-border: var(--color-primary-50);

  /* Three dot menu */
  --color-three-dot-icon-fill: var(--cool-gray-60);
  --color-three-dot-outline: var(--color-outline-base);
  --color-three-dot-hover-bg: var(--color-primary-80);
  --color-three-dot-accent-icon-fill: var(--color-accent);
  --color-three-dot-selected-bg: var(--color-primary-80);

  /* Illustrations */
  --color-illustration-stroke-1: #004440;
  --color-illustration-fill-0: #f5f7fa;
  --color-illustration-fill-1: #faa23a;
  --color-illustration-fill-2: #009dfa;
  --color-illustration-fill-3: #fac13a;
}

.keyValueList {
  display: grid;
  grid-template-columns: 40% auto;
  margin-bottom: var(--space-m);
}

.keyValueList.noBottomBorder {
  margin-bottom: var(--space-s);
}

.keyValueList.spaceBetween {
  grid-template-columns: 1fr min-content;
}

.keyValueList dt {
  grid-column: 1;
  font-weight: 500;
  color: var(--cool-gray-20);
}

.keyValueList dd {
  grid-column: 2;
}

.keyValueList.spaceBetween dd {
  text-align: end;
}

.noTopBorder {
}

.keyValueList:not(.noTopBorder) dt:first-of-type,
.keyValueList:not(.noTopBorder) dd:first-of-type {
  border-top: 1px var(--color-divider) solid;
}
.keyValueList dt,
.keyValueList dd {
  font-size: var(--text-s);
  border-bottom: 1px var(--color-divider) solid;
  padding: var(--padding-list-item);
}

.keyValueList.noHPadding dt,
.keyValueList.noHPadding dd {
  padding: var(--space-s) 0;
}

.keyValueList.noBottomBorder dt:last-of-type,
.keyValueList.noBottomBorder dd:last-of-type {
  border-bottom: none;
}

.alignLeft {
  justify-content: flex-start;
}

.alignRight {
  justify-content: flex-end;
}

.buttonGrid {
  align-items: center;
  display: flex;
  margin: var(--space-s) var(--space-m);
  gap: var(--space-m);
}

:global(.theme-lettuce) .buttonGrid {
  margin: var(--space-s) var(--space-m) var(--space-m);
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gap-m);
  gap: var(--space-s);
}

.button:disabled {
  cursor: default;
}

@value iconSize: 40px;
.button svg {
  flex-shrink: 0;
  width: iconSize;
  height: iconSize;
  fill: var(--color-card-icon);
  transition: fill 0.25s ease-in-out;
}

.button:active svg,
.button:disabled svg {
  fill: var(--cool-gray-40);
}

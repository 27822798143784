.container {
  position: relative;
  border-radius: var(--border-radius-primary) var(--border-radius-primary) 0 0;
  height: var(--input-height);
  min-width: 100px;
  background-color: var(--color-input-bg);
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  cursor: text;
  --content-l-padding: var(--space-m);
  --content-r-padding: var(--space-m);
}

.container:before {
  position: absolute;
  border-bottom: 1px solid var(--color-input-border);
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.container:after {
  border-bottom: 2px solid var(--color-input-focused-border);
  left: 0;
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.container.focused:after {
  transform: scaleX(1) translate(0);
}

.container:hover {
  background-color: var(--color-input-bg-hover);
}

.container.focused {
  background-color: var(--color-input-focused-bg);
}

.container svg {
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container.focused svg {
  fill: var(--input-focused-icon-fill);
}

.container.hasError {
  background-color: var(--color-input-error-bg);
}

.container.hasError svg {
  fill: var(--color-input-error);
}

.container.hasError:after {
  border-bottom-color: var(--color-input-error);
}

.container.disabled {
  cursor: default;
  opacity: 0.7;
}

.inputWithLabel {
  position: relative;
  cursor: text;
  padding: var(--space-s) var(--content-r-padding) var(--space-s) var(--content-l-padding);
}

.container.disabled .inputWithLabel {
  cursor: default;
}

.inputWithLabel input {
  background-color: transparent;
  border: none;
  outline: 0;
  width: 100%;
  display: block;
  font-size: var(--text-m);
  line-height: 24px;
  padding-top: var(--space-m);
}

.inputWithLabel input:disabled {
  /* Remove important after legacy global directives are removed */
  background-color: transparent !important;
}

.inputWithLabel input::-webkit-input-placeholder {
  color: var(--cool-gray-60);
}

.inputWithLabel label {
  font-weight: 500;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(var(--content-l-padding), calc((var(--input-height) - var(--text-m)) / 2))
    scale(1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform-origin: left top 0;
  transition:
    200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
}

.container.focused .inputWithLabel label,
.container.hasValue .inputWithLabel label {
  color: var(--color-input-focused-label-color);
  transform: translate(var(--content-l-padding), var(--space-s)) scale(0.8);
}

.container.hasError .inputWithLabel label {
  color: var(--color-input-error);
}

.iconInputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  pointer-events: none;
}

.iconInputWrapper.hasIconLeft {
  --content-l-padding: var(--space-s);
  padding-left: var(--space-m);
}

.iconInputWrapper.hasIconRight {
  --content-r-padding: var(--space-s);
  padding-right: var(--space-m);
}

/* The InputBase container (avoid declaring too many classes) */
.iconInputWrapper > div {
  flex: 1;
}

@value iconSize: var(--icon-size-primary);
.iconInputWrapper > svg {
  width: iconSize;
  height: iconSize;
  flex-shrink: 0;
}

.iconInputWrapper > svg {
  fill: var(--input-icon-fill);
}

.flex {
  display: flex;
  align-items: center;
}

.L {
  gap: var(--space-l);
}

.M {
  gap: var(--space-m);
}

.S {
  gap: var(--space-s);
}

.XS {
  gap: var(--space-xs);
}

.wrap {
  flex-wrap: wrap;
}

.spaceBetween {
  justify-content: space-between;
}

.column {
  flex-direction: column;
}

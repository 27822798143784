@value z-indices: '../../../z-indices.css';
@value overlay as overlayZIndex from z-indices;
@value colors: '../../../colors.css';
@value border from colors;

.overlayContainer {
  position: relative;
}

.overlay {
  background-color: #fff;
  font-size: var(--text-m);
  position: fixed;
  text-align: left;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: overlayZIndex;
}

@media only screen and (min-width: 768px) {
  .overlay {
    position: absolute;
    border-radius: 4px;
    border: 1px solid border;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    min-width: 150px;
    top: auto !important;
    bottom: auto;
    right: auto;
    left: 0;
  }

  .overlayLeft {
    left: auto;
    right: 0;
  }

  .overlayRight {
    left: 0;
    right: auto;
  }
}

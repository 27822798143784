body.theme-rema {
  --color-rema-blue: #023ea5;
  --color-rema-blue-secondary: #f7faff;
  --color-rema-blue-300: #70a0f2;
  --color-rema-blue-200: #235fc5;
  --color-rema-blue-highlight: #f3f7ff;
  --color-outline-base: var(--color-rema-blue-highlight);

  --color-accent: var(--color-rema-blue);
  --color-highlight: var(--color-rema-blue-highlight);

  --body-color-background: white;

  --color-primary: var(--color-rema-blue);
  --color-text-link: var(--color-primary);

  --color-button-link: var(--color-primary);
  --color-button-outline: var(--color-primary);

  --border-radius-primary: 4px;
  --color-menu-highlight-translucent: var(--color-rema-blue-secondary);

  /* List items */
  --color-list-item-bg-focused: var(--color-rema-blue-secondary);
  --color-list-item-focused: var(--color-rema-blue);

  /* Label */
  --color-label-text: var(--color-text-primary);
  --color-label-border: var(--cool-gray-80);
  --color-label-bg: var(--cool-gray-95);

  /* Chips */
  --color-chip-hover-bg: var(--color-rema-blue-secondary);
  --color-chip-hover-border: #709bf2;
  --color-chip-hover: var(--color-rema-blue);
  --color-chip-outline: var(--color-rema-blue-300);

  /* Modal */
  --color-modal-background: white;

  /* Buttons */
  --color-button-outline: var(--color-outline-base);
  --color-button-primary: white;
  --color-button-primary-bg: var(--color-accent);
  --color-button-primary-border: var(--color-accent);
  --color-button-primary-hover-bg: var(--color-rema-blue-200);
  --color-button-primary-hover-border: var(--color-rema-blue-200);
  --color-button-primary-active-border: var(--color-rema-blue-200);
  --color-button-primary-disabled-bg: var(--cool-gray-60);

  --color-button-secondary: var(--color-accent);
  --color-button-secondary-bg: white;
  --color-button-secondary-border: var(--cool-gray-80);
  --color-button-secondary-hover-bg: var(--color-highlight);
  --color-button-secondary-hover-border: var(--color-accent);
  --color-button-secondary-active-bg: white;
  --color-button-secondary-active-border: var(--color-accent);
  --color-button-secondary-disabled: var(--cool-gray-60);
  --color-button-secondary-disabled-border: var(--cool-gray-80);

  --color-button-text: var(--color-accent);
  --color-button-text-hover: var(--color-rema-blue-200);
}

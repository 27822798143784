.container {
  height: 16px;
  position: relative;
  width: 16px;
  flex-shrink: 0;
  flex-grow: 0;
}

.input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.overlay {
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.overlay:not(.checked) {
  border: 2px solid var(--color-checkbox-unchecked);
  background-color: white;
}

.checked {
  background-color: var(--color-checkbox-checked);
  border-color: var(--color-checkbox-checked);
}

.container {
  display: flex;
  gap: var(--space-s);
  justify-content: flex-end;
  align-items: center;
  padding: var(--space-m);
  /* Ensure this does not obscure a parent panel's border radius if its overflow is not hidden. */
  border-bottom-left-radius: var(--border-radius-panel);
  border-bottom-right-radius: var(--border-radius-panel);
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.container.hasTertiary {
  justify-content: space-between;
}

.container > div {
  display: flex;
  gap: var(--space-s);
  align-items: center;
}

.button {
  border: none;
  border-radius: 50%;
  background-color: transparent;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.25s;
}

.button.sizeS {
  width: 24px;
  height: 24px;
}

.button:focus-visible {
  outline-color: var(--color-three-dot-outline);
}

.button.neutral:focus-visible {
  outline-color: var(--color-three-dot-neutral-outline);
}

.button:hover {
  background-color: var(--color-three-dot-hover-bg);
}

.button.neutral:hover {
  background-color: var(--color-three-dot-neutral-hover-bg);
}

.button.red:hover {
  background-color: var(--color-three-dot-red-bg);
}

@value iconSize: 20px;
@value iconSizeS: 18px;

.button svg {
  fill: var(--color-three-dot-icon-fill);
  width: iconSize;
  height: iconSize;
}

.button.sizeS svg {
  width: iconSizeS;
  height: iconSizeS;
}

.button:hover svg {
  fill: var(--color-three-dot-accent-icon-fill);
}

.button.neutral:hover svg {
  fill: var(--color-three-dot-neutral-accent-icon-fill);
}

.toggled {
  background-color: var(--color-three-dot-selected-bg);
}

.neutral.toggled {
  background-color: var(--color-three-dot-neutral-selected-bg);
}

.button.toggled svg {
  fill: var(--color-three-dot-accent-icon-fill);
}

.button.neutral.toggled svg {
  fill: var(--color-three-dot-neutral-accent-icon-fill);
}

.button.red svg {
  fill: var(--color-three-dot-red-icon-fill);
}

.button.red.toggled svg {
  fill: var(--color-three-dot-red-icon-fill);
}

@value colors: '../../../colors.css';
@value link, default, border from colors;

@value m: var(--space-m);
@value s: var(--space-s);

.body {
  margin-top: m;
  margin-bottom: m;
}

.tabs {
  -webkit-overflow-scrolling: touch;
  display: flex;
  margin: 0 s;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tabs a:hover,
.tabs .tabLink:hover {
  text-decoration: none;
}


.tabs a {
  font-weight: 500;
}

.tabLink {
  font-weight: 500;
  margin: s s 0;
  padding: s s m;
  white-space: nowrap;
}

.tabButton {
  margin: 0 s;
}

.tabButton:hover {
  text-decoration: none;
}

.tabLink:first-child,
.tabButton:first-child {
  margin-left: 1px;
}

.tabLink:last-child,
.tabButton:last-child {
  margin-right: 1px;
}

.tabLink > *,
.tabButton > * {
  color: default;
}

.selected {
  color: link;
  position: relative;
}
.selected::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: calc(100% - s * 2);
  left: s;
  border-bottom: 2px solid link;
}
.selected:focus {
  outline: 1px solid link;
}
.selected:focus::before {
  width: 100%;
  left: 0;
}

.tabsContainer {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.divider {
  margin-top: 0;
  margin-bottom: m;
}

.rightContainer {
  bottom: 0;
  right: 0;
  margin: 0 m 0 auto;
  flex: 0;
  white-space: nowrap;
}

.overflowContainer {
  overflow: hidden;
  position: relative;
}


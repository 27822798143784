@value z-indices: '../../../../z-indices.css';
@value overlay from z-indices;

.container {
  position: relative;
}

.selectedOptionsList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  max-width: 100%;
}

.multiSelectDropdown {
  height: 48px;
  font-size: unset;
  border: 1px solid #aaa;
  width: 100%;
  text-align: left;
  padding: var(--space-s);
  background-color: var(--body-color-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multiSelectDropdown:focus {
  outline: var(--color-primary) auto 1px;
}

.multiSelectDropdownOpen {
  outline: var(--color-primary) auto 1px;
}

.multiSelectDropdownButtonText, .placeholder {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiSelectPopover {
  z-index: overlay;
  overflow: auto;
  border-radius: 4px;
  outline: 1px solid var(--color-primary);
  background-color: var(--body-color-background);
}

.multiSelectList {
  max-height: 200px;
  overflow-y: auto;
  outline: none;
}

.multiSelectList > li {
  padding: 2px;
  white-space: normal;
  align-items: baseline;
}

.multiSelectList > li div:last-child {
  margin-right: var(--space-m);
}

.multiSelectArrow {
  margin-left: 6px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  stroke: #aaa;
  stroke-width: 2px;
}

.placeholder, .loading {
  color: var(--color-text-subtle);
}

.disabledField {
  background-color: #f5f5f5;
}

.error {
  color: #fa7272;
}

.searchInputContainer {
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchInput {
  outline: none !important;
  border: none !important;
  border-bottom: 2px solid var(--color-primary) !important;
}

.footer {
  position: sticky;
  bottom: 0;
  background: var(--body-color-background);
  padding: var(--space-s);
  border-top: 1px solid var(--color-primary);
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  gap: var(--space-s);
  padding: var(--space-s);
  align-items: center;
}

.selectAll {
  border-bottom: 1px solid #ebe8e3;
  margin-right: 0 !important;
}

.list {
  margin: 0;
  outline-offset: 1px;
  outline-color: var(--color-outline);
  overflow: auto;
}

.showMoreListItem {
  padding: var(--space-xs) var(--gap-s);
}

.menuItems {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.dashboardMenuItemsWrapper {
  height: 100vh;
  width: 75vw;
  overflow-y: auto;
  padding: var(--space-l);
}

.resourcesMenuItemsWrapper {
  height: 100vh;
  width: 30vw;
  overflow-y: auto;
  padding: var(--space-l);
}

@media (max-width: 768px) {
  .dashboardMenuItemsWrapper, .resourcesMenuItemsWrapper {
    width: 100vw;
  }
}

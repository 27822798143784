.listItem {
  display: flex;
  margin: 0 var(--space-s);
  border-top: 1px solid var(--color-list-separator);
  cursor: pointer;
  align-items: center;
  padding: 0 var(--space-s) 0 var(--space-m);
  gap: var(--space-s);
}

.listItem:first-child {
  flex: 1;
}

.listItem:first-child {
  flex: 0;
}

.current {
  background-color: var(--cool-gray-95);
}

.listItem:hover {
  color: var(--color-menu-item-focused);
  background-color: var(--color-menu-item-bg-focused);
}

.link {
  display: flex;
  justify-content: space-between;
  gap: var(--space-m);
  flex: 1;
  align-items: center;
  margin: var(--space-m) 0;
  text-decoration: none !important;
}

.name {
  font-weight: 500;
  color: var(--color-text-primary);
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: var(--space-s);
}

.listItem:hover .name {
  color: var(--color-menu-item-focused);
}

.listOfLabels {
  justify-content: flex-end;
}

.buttonWrapper {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-left: var(--space-s);
  position: relative;
  top: 2px;
}

.buttonWrapper > * {
  pointer-events: none;
}

.tooltipContent {
  max-width: 390px;
}

.buttonWrapper :global(.svg-fill) {
  fill: var(--color-text-primary);
}

.buttonWrapper :global(.svg-stroke) {
  stroke: var(--color-text-primary);
}

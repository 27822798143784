:root {
  /* This should always be specified locally, depending on use case.
  There is no standard (default) value for this animation. */
  --slide-up-by: 0;
  --fade-in-to-opacity: 1;
}

@keyframes slide-up {
  0% {
    transform: translateY(var(--slide-up-by));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: var(--fade-in-to-opacity);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drawer-slide-left {
  0% {
    transform: translateX(var(--drawer-size));
  }
  100% {
    transform: translateX(0);
  }
}

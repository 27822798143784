@value z-indices: '../../../z-indices.css';
@value backdrop from z-indices;

.modal {
  background-color: #fff;
  max-width: 100%;
  max-height: calc(90vh - 60px);
  position: absolute;
  width: 850px;
}

.modal.small {
  width: 500px;
}

.modal.medium {
  width: 700px;
}

.modal.xlarge {
  width: 1024px;
}

.modal.fullScreen {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.modalBody {
  min-height: auto;
}

.modal.fullScreen .modalBody {
  height: 100%;
}

/* Not strictly necessary, it's used to silence the linter */
.noPadding {
  padding: 0;
}

:global(.theme-lettuce) .modal {
  padding: 0 !important;
}
/* when viewing lettuce theme but not using the new modal component */
:global(.theme-lettuce) .modal:global(:not(.lettuce-modal-panel)) .modalBody > * {
  padding-left: var(--space-m);
  padding-right: var(--space-m);
}

@media only screen and (min-width: 768px) {
  :global(.theme-legacy) .modal:not(.fullScreen), :global(.theme-unfi) .modal:not(.fullScreen) {
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 10px 0;
  }
  .modal:not(.fullScreen) {
    margin-top: 60px;
  }

  .modal:not(.fullScreen) .modalBody {
    max-height: calc(90vh - 120px);
  }

  :global(.theme-legacy) .modal:not(.fullScreen) .modalBody:not(.noPadding) {
    padding: 15px;
  }

  :global(.theme-rema) .modal:not(.fullScreen) {
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 10px 0;
  }
  :global(.theme-rema) .modal:not(.fullScreen) .modalBody:not(.noPadding) {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .modal {
    min-height: 100%;
  }

  .modal:not(.fullScreen) .modalBody {
    max-height: calc(90vh);
  }
}

.errorMessage {
  margin-right: var(--space-l) !important;
  position: sticky !important;
  top: var(--space-m);
  z-index: backdrop;
}

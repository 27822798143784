body.theme-advantage {
  --color-advantage-blue: #2a6bbc;

  --navrail-color-background: #1e3e64;
  --navrail-menu-item-color-icon: white;
  --navrail-menu-item-color-icon-hover: white;
  --navrail-menu-item-color-hover: #3d6da9;
  --navrail-menu-item-label-color: white;
  --navrail-menu-item-label-color-hover: white;
  --navrail-menu-item-selected-color-icon: white;
  --navrail-menu-item-selected-label-color: white;

  --blue-0: #e9eff7;

  --blue-vivid-0: #f5f9ff;
  --blue-vivid-100: #c5defd;
  --blue-vivid-200: #98c3f9;
  --blue-vivid-400: #5095e9;
  --blue-vivd-500: #3882dd;
  --blue-vivid-600: #2771cd;
  --blue-vivid-700: #1b61b8;

  --color-text-primary: var(--cool-gray-40);
  --color-text-secondary: var(--cool-gray-50);
  --color-text-subtle: var(--cool-gray-60);
  --color-text-heading: var(--cool-gray-20);
  --color-accent: var(--blue-vivid-600);
  --color-highlight: var(--blue-vivid-0);
  --color-text-highlight: var(--blue-vivd-500);
  --color-text-link: var(--color-accent);

  /* Utility colors */
  --color-error: #f42e48;
  --color-info: #386fdd;
  --color-warning: #f6a121;
  --color-success-light: #5bbe61;

  /* Misc */
  --color-outline-base: var(--blue-vivid-100);

  --body-color-background: var(--cool-gray-95);
  --slightly-darker-body-color-background: hsla(216, 100%, 94%, 1);

  /* Buttons */
  --color-button-outline: var(--color-outline-base);
  --color-button-primary: white;
  --color-button-primary-bg: var(--blue-vivid-700);
  --color-button-primary-border: var(--blue-vivid-700);
  --color-button-primary-hover-bg: var(--blue-vivd-500);
  --color-button-primary-hover-border: var(--blue-vivd-500);
  --color-button-primary-active-border: var(--blue-vivid-700);
  --color-button-primary-disabled-bg: var(--cool-gray-60);

  --color-button-secondary: var(--color-accent);
  --color-button-secondary-bg: white;
  --color-button-secondary-border: var(--cool-gray-80);
  --color-button-secondary-hover-bg: var(--color-highlight);
  --color-button-secondary-hover-border: var(--blue-vivid-400);
  --color-button-secondary-active-bg: white;
  --color-button-secondary-active-border: var(--color-accent);
  --color-button-secondary-disabled: var(--cool-gray-60);
  --color-button-secondary-disabled-border: var(--cool-gray-80);

  --color-button-text: var(--blue-vivid-700);
  --color-button-text-hover: var(--color-accent);

  /* Chips */
  --color-chip-bg: white;
  --color-chip-border: var(--cool-gray-80);
  --color-chip-hover: var(--color-accent);
  --color-chip-hover-bg: var(--color-highlight);
  --color-chip-hover-border: var(--color-accent);
  --color-chip-selected-border: var(--color-accent);
  --color-chip-selected-bg: var(--color-highlight);
  --color-chip: var(--color-text-primary);
  --color-chip-selected: var(--color-accent);
  --color-chip-icon-fill: var(--cool-gray-60);
  --color-chip-hover-icon-fill: var(--color-accent);
  --color-chip-selected-icon-fill: var(--color-accent);
  --color-chip-outline: var(--color-outline-base);

  --color-list-of-chips-bg: transparent;

  /* Modal */
  --color-modal-background: white;

  /* Panels */
  --color-surface: white;
  --color-panel-border: var(--color-accent);
  --color-panel-neutral-border: var(--cool-gray-90);

  /* Input */
  --color-input-bg: var(--cool-gray-95);
  --color-input-bg-hover: #eef0f5;
  --color-input-focused-bg: var(--color-highlight);
  --color-input-border: var(--cool-gray-60);
  --color-input-focused-border: var(--color-accent);
  --color-input-error: var(--color-error);
  --color-input-error-bg: #fff0f0;
  --color-input-focused-label-color: var(--color-accent);
  --input-icon-fill: var(--cool-gray-60);
  --input-focused-icon-fill: var(--color-accent);

  /* Checkbox */
  --color-checkbox-unchecked: var(--color-accent);
  --color-checkbox: var(--color-accent);
  --color-checkbox-checked: var(--color-accent);

  /* Cards */
  --color-card-icon: var(--color-accent);
  --color-card-border: var(--cool-gray-90);
  --color-card-border-hover: var(--blue-vivid-400);
  --color-card-background-hover: var(--color-surface);

  /* Menu */
  --color-menu-item: var(--cool-gray-40);
  --color-menu-item-bg-focused: var(--color-highlight);
  --color-menu-item-focused: var(--color-accent);
  --color-menu-separator: var(--color-divider);
  --color-menu-item-icon: var(--color-accent);
  --color-menu-item-focused-icon: var(--color-accent);

  /* List */
  --color-list-item: var(--cool-gray-30);
  --color-list-item-bg-focused: var(--color-highlight);
  --color-list-item-selected: var(--color-accent);
  --color-list-item-bg-selected: var(--cool-gray-95);
  --color-list-item-focused: var(--color-accent);
  --color-list-separator: var(--color-divider);
  --color-list-item-icon: var(--cool-gray-30);
  --color-list-item-focused-icon: var(--color-list-item-focused);

  /* Badge (e.g. Beta badge) */
  --color-badge-bg: var(--color-highlight);
  --color-badge-border: var(--blue-vivid-400);
  --color-badge: var(--color-accent);

  --color-button-icon: var(--color-accent);
  --color-button-icon-hover: var(--blue-vivid-400);
}

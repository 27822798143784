.icon {
  fill: var(--color-checkbox-unchecked);
  flex-shrink: 0;
}

.icon.checked {
  fill: var(--color-checkbox-checked);
}

.M {
  width: var(--icon-size-primary);
  height: var(--icon-size-primary);
}

.S {
  width: var(--icon-size-secondary);
  height: var(--icon-size-secondary);
}

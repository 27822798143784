@value verticalPadding: 7px;
@value horizontalPadding: 11px;

.chip,
a.chip {
  border: 1px solid var(--color-chip-border);
  border-radius: 20px;
  padding: verticalPadding horizontalPadding;
  font-weight: 500;
  font-size: var(--text-s);
  background-color: var(--color-chip-bg);
  line-height: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  transition:
    background-color 0.2s ease-in,
    border-color 0.2s ease-in,
    color 0.2s ease-in;
  color: var(--color-chip);
}

.chip.disabled,
a.chip.disabled {
  border: 1px solid var(--cool-gray-80);
  color: var(--cool-gray-60);
  background-color: var(--cool-gray-80);
  pointer-events: none;
}

.chip.flex,
a.chip.flex {
  display: flex;
  gap: var(--space-xs);
  align-content: center;
}

/* Padding Right Small */
.chip.prs,
a.chip.prs {
  padding-right: verticalPadding;
}

/* Padding Left Small */
.chip.pls,
a.chip.pls {
  padding-left: verticalPadding;
}

@value iconSize: 18px;
.chip svg {
  width: iconSize;
  height: iconSize;
}

.chip:focus-visible {
  border-color: var(--color-chip-hover-border);
  outline: solid var(--color-chip-outline);
  outline-width: 3px;
}

a.chip,
a.chip:hover {
  text-decoration: none;
}

button.chip {
  cursor: pointer;
}

button.chip:not(.selected):hover,
a.chip:not(.selected):hover {
  background-color: var(--color-chip-hover-bg);
  border-color: var(--color-chip-hover-border);
  color: var(--color-chip-hover);
}

.S {
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.5rem;
  height: 28px;
}

.S.chip.prs {
  padding-right: 6px;
}

.S.chip.pls {
  padding-left: 6px;
}

.chip.S svg {
  width: 16px;
  height: 16px;
}

.selected {
  background-color: var(--color-chip-selected-bg);
  border-color: var(--color-chip-selected-border);
  color: var(--color-chip-selected);
}

.content {
  display: flex;
  gap: var(--space-xs);
}

.chip svg {
  fill: var(--color-chip-icon-fill);
  transition:
    transform 0.25s ease-in-out,
    fill 0.25s;
}

.chip:hover svg {
  fill: var(--color-chip-hover-icon-fill);
}
.chip.selected svg {
  fill: var(--color-chip-selected-icon-fill);
}

.toggled svg:last-child {
  transform: rotate(180deg);
}

.chipCombo {
  display: flex;
  flex-wrap: nowrap;
}

.chipCombo > .chip {
  position: relative;
  /* Necessary for the interstitial border effect. This does not cause any issues in this context */
  overflow: unset;
}

.chipCombo > .chip:after {
  transition: background-color 0.2s ease-in;
}

.chipCombo > .chip:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.chipCombo > .chip:first-of-type {
  /* -1px from default 12px to make up for the collapsed border effect */
  padding-right: calc(horizontalPadding - 1px);
}

.chipCombo > .chip.prs:first-of-type {
  /* -1px from default 8px to make up for the collapsed border effect */
  padding-right: calc(verticalPadding - 1px);
}

.chipCombo > .chip.S:first-of-type {
  /* -1px from default 10px to make up for the collapsed border effect */
  padding-right: 9px;
}

.chipCombo > .chip:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.chipCombo > .chip + .chip:after {
  content: '';
  height: calc(100% + 2px);
  position: absolute;
  width: 1px;
  background-color: var(--color-chip-border);
  top: -1px;
  left: 0;
}

.chipCombo > .chip:hover + .chip:after {
  background-color: var(--color-chip-selected-border);
}

.chipCombo > .chip + .chip:hover:after {
  background-color: var(--color-chip-selected-border);
}

.chipCombo > .chip:focus-visible {
  z-index: 1;
}

.chipCombo > .chip:focus-visible + .chip:after {
  background-color: var(--color-chip-selected-border);
  /* Render the interstitial border on top of the focus outline */
  z-index: 1;
}

.chipCombo > .chip + .chip:focus-visible:after {
  background-color: var(--color-chip-selected-border);
  z-index: 1;
}

.chipCombo > .chip.selected + .chip:after {
  background-color: var(--color-chip-selected-border);
  z-index: 1;
}

.chipCombo > .chip + .chip.selected:after {
  background-color: var(--color-chip-selected-border);
  z-index: 1;
}

.valueText {
  color: var(--color-accent);
}

.labelText + span {
  padding: 0 2px;
  color: var(--cool-gray-70);
}

@value z-indices: '../../z-indices.css';
@value notificationPanel as notificationPanelZIndex from z-indices;

@value m: var(--space-m);
@value s: var(--space-s);

.notificationPanel {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: notificationPanelZIndex;
  padding: 0 m m var(--navrail-width);
}

.notificationPanel.reducedOffset {
  padding: 0 m m var(--navrail-compact-width);
}

@media only screen and (max-width: 400px) {
  .notificationPanel {
    padding: 0 s s s;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.wrapper {
  position: relative;
  width: 100%;
}

.wrapper:after {
  position: absolute;
  right: 0;
  top: calc(50% - 7px);
  color: #767676;
  content: '\25bc';
  line-height: 1;
  pointer-events: none;
  text-align: center;
  transform: scale(0.86, 0.42);
  width: 32px;
  -webkit-font-smoothing: none;
}

.select {
  -webkit-appearance: none;
  height: 48px;
  padding: 13px 30px 13px 10px;
  width: 100%;
}

.hasError {
  background-color: rgb(255, 246, 246);
  border-color: #e0b4b4;
  color: #9f3a38;
}

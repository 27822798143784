@value arrowWidth: 9px;
@value arrowHeight: 7px;

.arrow {
  position: absolute;
  pointer-events: none;
}

.arrow:before {
  content: '';
  z-index: -1;
  position: absolute;
  top: calc(-1 * arrowWidth);
  left: calc(-2 * arrowHeight);
  width: 0;
  height: 0;
  border-width: arrowWidth arrowHeight arrowWidth arrowHeight;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.searchBarContainer {
  position: absolute;
  top: calc(var(--space-m) + var(--space-s));
  right: var(--space-m);
  width: 80px;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
}

.searchBarContainer.active {
  width: calc(100% - 180px);
}

.searchBarButton {
  float: right;
}

.searchBar {
  display: flex;
  align-items: center;
  gap: var(--space-s);
  padding: var(--space-xs) var(--space-s);
  border: 1px solid var(--color-chip-selected-border);
  border-radius: 100px;
  background-color: var(--color-chip-selected-bg);
  height: calc(var(--space-l) + 2px);
}

.searchInput {
  border: none;
  background-color: unset;
  flex-grow: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.searchInput:focus {
  outline: none;
}

.searchInput.ready {
  visibility: visible;
  opacity: 1;
}

@value iconSize: 18px;
.icon {
  fill: var(--color-chip-selected);
  height: iconSize;
  width: iconSize;
  position: relative;
}

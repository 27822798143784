.body {
  max-width: 1024px;
  /* The following three directives are necessary to accommodate margins leaked from inner top elements
  such as headers. Overflow auto also works however it invalidates all sticky positioned effects for child elements.  */
  padding-top: 1px;
  position: relative;
  top: -2px;
}

.S {
  max-width: 800px;
}

.L {
  max-width: 1280px;
}

.center {
  margin: 0 auto;
}

@value colors: '../../../../colors.css';
@value default, border from colors;

.listOfOptions {
  font-size: var(--text-s);
  text-align: left;
  margin: 0;
}

.optionsContainer {
  overflow: auto;
  margin: 0;
}

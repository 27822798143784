.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  margin: 0 var(--space-xs);
}

.next {
  margin-left: var(--space-xs);
}

.basic {
  display: flex;
  justify-content: center;
  align-items: center;
}

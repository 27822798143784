@value gapSize: var(--space-s);

.container {
  display: flex;
  align-items: center;
}

.container label {
  font-size: var(--text-s);
  font-weight: 500;
  color: var(--color-text-heading);
  padding-left: gapSize;
  cursor: pointer;
}

.details {
  /* Ensure supporting text has the same lef offset */
  padding-left: calc(gapSize + var(--icon-size-primary)) !important;
}

.hasError {
}
/* Keep the error message at a higher offset from the input. The details should be closer for checkbox and radio fields */
.details:not(.hasError) {
  padding-top: 0;
}

.container.disabled label {
  color: var(--color-disabled);
}

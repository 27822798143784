@value colors: '../../../../colors.css';
@value textAreaBackground, disabled from colors;

.textArea {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #aaa;

  font-size: 1.6rem;
  line-height: 2.5rem;

  padding: var(--space-s);
  resize: none;
  width: 100%;
}

textarea.hasError {
  border: 1px solid var(--color-field-error);
}

.label {
  font-size: var(--text-l);
  font-weight: 400;
}

.labelDescription {
  color: var(--color-text-subtle);
}

.labelDescription > p {
  margin: 0;
}

.fieldError {
  color: var(--color-field-error);
  margin-top: var(--space-s);
}

.hidden {
  display: none;
}

.textSubmitContainer {
  display: flex;
  gap: var(--space-m);
}

.textSubmitContainer > div:first-child {
  flex-grow: 1;
}

.autoSuggestContainer {
  width: 100%;
}

.checkboxField {
  display: flex;
  gap: var(--space-s);
  align-items: center;
  margin: var(--space-m);
}

.mbs {
  margin-bottom: var(--space-s);
}

.checkboxDescription {
  color: var(--color-text-subtle);
  margin: 0 var(--space-m);
}

.label {
  font-size: var(--text-l);
  font-weight: 400;
}

.labelDescription {
  color: var(--color-text-subtle);
}

.errorMessage {
  color: #d93900;
  margin-top: var(--space-s);
}

.hidden {
  display: none;
}

.verticalStretchContainer {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.maxWidthCenterFullScreenModal {
  max-width: 1400px;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-m) var(--space-s) var(--space-m) 0;
}

.headerContainer h3 {
  /* Putting the margin on the container lets flexbox align the h3 with the button on the right better */
  margin-top: 0;
  margin-bottom: 0;
}

.listContainer {
  margin-left: calc(var(--space-l) + var(--space-m));
}

.empty {
  margin: var(--space-m);
  margin-left: calc(2 * var(--space-l) + var(--space-s));
}

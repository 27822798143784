.stretchContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex-grow: 1;
  overflow-y: auto;
  /* Main content will use all available height and can stretch if necessary */
  display: flex;
  flex-direction: column;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
  /* Prevent overlap with the "Close" button */
  margin-right: 60px;
}

/* In a full screen modal there may be nested modals which are allowed to obscure the parent modal's close button */
.modal button[aria-label='Close'] {
  z-index: initial;
}

@value z-indices: '../../../../z-indices.css';
@value notificationPanel as notificationPanelZIndex from z-indices;

.panel:empty {
  display: none;
}

.panel {
  position: fixed;
  bottom: 0;
  left: var(--space-l);
  right: var(--space-l);
  display: flex;
  justify-content: center;
  z-index: notificationPanelZIndex;
}

.notificationContainer {
  width: 100%;
  max-width: 1024px;
}

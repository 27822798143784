@value size: var(--icon-size-primary);

.inputContainer {
  height: size;
  width: size;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
}


.input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  width: 100%;
  -webkit-appearance: none;
}

.inputOverlay {
  align-content: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  pointer-events: none;
  border-radius: 6px;
}

.round {
  border-radius: 50%;
}

.inputOverlay svg {
  fill: var(--color-checkbox-unchecked);
}

.inputOverlay.checked svg {
  fill: var(--color-checkbox-checked);
}

.disabled svg,
.disabled.checked svg {
  fill: var(--color-disabled);
}

.focus {
  outline-offset: -3px;
  outline: var(--color-outline-base) solid 3px;
}

.round.focus {
  outline-offset: -2px;
}

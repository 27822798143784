.container {
  padding: var(--space-m);
  background-color: #f1f1f1;
  border-radius: 4px;
}

.container > iframe {
  height: calc(80vh - 250px);
  box-shadow: 2px 2px 5px 0 #e4e4e4;
}


.panel {
  padding: var(--space-m) var(--space-s);
  text-align: center;
  min-width: 480px;
  max-width:490px;
}
.panel > div {
  padding: var(--space-s) 0;
}


.panel input {
  width: 100%;
  min-width:400px;
}

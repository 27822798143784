.main {
  margin: 0 auto;
}

/* Sizing */

.width-default {
  max-width: 1024px;
}

.width-wide {
  max-width: 1280px;
}

.width-floating {
  max-width: initial;
  margin: 0 var(--space-m);
}

.width-floating-no-margin {
  max-width: initial;
}


.header {
  align-items: center;
  display: flex;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1240px) {
  .header {
    padding-left: 40px;
    padding-right: 40px;
  }
}

:global(.theme-lettuce) .header {
  height: 80px;
}

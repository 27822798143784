@value modalPadding: 15px;

.header {
  position: sticky;
  top: 0;
  background-color: white;
  margin: 0;
  padding: calc(var(--space-m) + modalPadding) modalPadding 5px modalPadding;
}

.body {
  min-height: 400px;
  padding: 0 modalPadding var(--space-l) modalPadding;
}

.groupName {
  text-transform: uppercase;
  color: var(--docs-text-color-subtle);
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: var(--space-xs);
}

.group {
  margin: var(--space-m) var(--space-m) var(--space-s) var(--space-m);
}

.group:first-child {
  margin-top: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table tr {
  border-bottom: 1px solid var(--docs-table-color-border);
}

.table td {
  border: none;
  padding: modalPadding 0;
}

.table td:first-child {
  width: 40%;
}

body.theme-lettuce {
  /* Colors */
  --teal-vivid-10: #004440;
  --teal-vivid-20: #016457;
  --teal-vivid-30: #048271;
  --teal-vivid-40: #079a82;
  --teal-vivid-50: #17b897;
  --teal-vivid-60: #2dcca7;
  --teal-vivid-70: #5fe3c0;
  --teal-vivid-80: #8eedd1;
  --teal-vivid-90: #c6f7e9;
  --teal-vivid-95: #f3fdf9;

  --cool-gray-10: #1f2933;
  --cool-gray-20: #323f4b;
  --cool-gray-30: #3e4c59;
  --cool-gray-40: #52606d;
  --cool-gray-50: #616e7c;
  --cool-gray-60: #7b8794;
  --cool-gray-70: #9aa5b1;
  --cool-gray-80: #e7ebef;
  --cool-gray-90: #eceff4;
  --cool-gray-95: #f5f7fa;

  --green-10: #05400a;
  --green-20: #0e5814;
  --green-30: #207227;
  --green-40: #2f8132;
  --green-50: #3f9142;
  --green-60: #57ae5b;
  --green-70: #7bc47f;
  --green-80: #a3d9a5;
  --green-90: #c1eac5;
  --green-95: #e3f9e5;

  --font-primary: 'Outfit', 'sans-serif';
  --font-mono: 'Roboto Mono', monospace;

  /* Utility colors */
  --color-error: #f42e48;
  --color-error-light: #f86a6a;
  --color-info: #386fdd;
  --color-warning: #f6a121;
  --color-success-light: #5bbe61;

  --body-color-background: var(--cool-gray-95);
  --slightly-darker-body-color-background: hsla(156, 71%, 91%, 1);

  /* Spacing */
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 32px;
  --space-xl: 64px;

  --gap-s: calc(var(--space-xs) + var(--space-s));
  --gap-m: calc(var(--space-s) + var(--space-m));

  /* Font sizes */
  --text-xxs: 1.1rem;
  --text-xs: 1.2rem;
  --text-s: 1.4rem;
  --text-m: 1.6rem;
  --text-l: 1.8rem;

  --border-radius-primary: 4px;
  --border-radius-card: 8px;
  --border-radius-panel: var(--border-radius-card);

  --navrail-color-background: var(--teal-vivid-10);
  --navrail-width: 80px;
  --navrail-compact-width: 60px;
  --navrail-menu-item-color-icon: #d3f8e4;
  --navrail-menu-item-color-icon-hover: #d3f8e4;
  --navrail-menu-item-color-hover: var(--color-accent);
  --navrail-menu-item-label-color: #6acd98;
  --navrail-menu-item-label-color-hover: white;
  --navrail-menu-item-selected-color-icon: white;
  --navrail-menu-item-selected-label-color: white;

  --color-text-primary: var(--cool-gray-40);
  --color-text-secondary: var(--cool-gray-50);
  --color-text-subtle: var(--cool-gray-60);
  --color-text-heading: var(--cool-gray-20);
  --color-text-darker: var(--cool-gray-30);
  --color-accent: var(--teal-vivid-30);
  --color-highlight: var(--teal-vivid-95);
  --color-text-highlight: var(--green-40);
  --color-text-link: var(--color-accent);
  --color-banner-background: var(--green-10);

  /* Buttons */
  --color-button-outline: var(--color-outline-base);
  --color-button-primary: white;
  --color-button-primary-bg: var(--teal-vivid-20);
  --color-button-primary-border: var(--teal-vivid-20);
  --color-button-primary-hover-bg: var(--teal-vivid-40);
  --color-button-primary-hover-border: var(--teal-vivid-40);
  --color-button-primary-active-border: var(--teal-vivid-20);
  --color-button-primary-disabled-bg: var(--cool-gray-60);

  --color-button-secondary: var(--teal-vivid-20);
  --color-button-secondary-bg: white;
  --color-button-secondary-border: var(--cool-gray-80);
  --color-button-secondary-hover-bg: var(--color-highlight);
  --color-button-secondary-hover-border: var(--teal-vivid-50);
  --color-button-secondary-active-bg: white;
  --color-button-secondary-active-border: var(--color-accent);
  --color-button-secondary-disabled: var(--cool-gray-60);
  --color-button-secondary-disabled-border: var(--cool-gray-80);

  --color-button-text: var(--teal-vivid-20);
  --color-button-text-hover: var(--teal-vivid-30);

  --color-button-icon: var(--color-accent);
  --color-button-toolbar-icon: var(--cool-gray-60);
  --color-button-icon-hover: var(--teal-vivid-50);

  /* List */
  /* This is currently applied for lists in dropdowns, styles may differ for page inline lists and will be defined later */
  --text-list: var(--text-s);
  --font-weight-list: 500;
  --color-list-item: var(--color-text-darker);
  --color-list-item-bg-focused: var(--color-highlight);
  --color-list-item-selected: var(--color-accent);
  --color-list-item-bg-selected: var(--cool-gray-95);
  --color-list-item-focused: var(--color-accent);
  --color-list-separator: var(--color-divider);
  --color-list-item-icon: var(--color-text-darker);
  --color-list-item-focused-icon: var(--color-list-item-focused);
  --padding-list-item: var(--space-s) calc(var(--space-s) + var(--space-s) / 2);
  --margin-list-item: 0;

  /* Menu */
  --text-menu: 1.5rem;
  --font-weight-menu: 500;
  --color-menu-item: var(--cool-gray-40);
  --color-menu-item-bg-focused: var(--color-highlight);
  --color-menu-item-focused: var(--color-accent);
  --color-menu-separator: var(--color-divider);
  --color-menu-item-icon: var(--color-accent);
  --color-menu-item-focused-icon: var(--color-accent);
  --color-menu-item-bg-selected: var(--cool-gray-80);
  --color-menu-item-selected: var(--cool-gray-30);

  /* Panels */
  --color-surface: white;
  --color-panel-border: var(--color-accent);
  --color-panel-neutral-border: var(--cool-gray-90);

  /* Chips */
  --color-chip-bg: white;
  --color-chip-border: var(--cool-gray-80);
  --color-chip-hover: var(--color-accent);
  --color-chip-hover-bg: var(--color-highlight);
  --color-chip-hover-border: var(--color-accent);
  --color-chip-selected-border: var(--color-accent);
  --color-chip-selected-bg: var(--color-highlight);
  --color-chip: var(--color-text-primary);
  --color-chip-selected: var(--color-accent);
  --color-chip-icon-fill: var(--cool-gray-60);
  --color-chip-hover-icon-fill: var(--color-accent);
  --color-chip-selected-icon-fill: var(--color-accent);
  --color-chip-outline: var(--color-outline-base);

  --color-list-of-chips-bg: transparent;

  /* Auxiliary interactive elements */
  --color-three-dot-icon-fill: var(--cool-gray-60);
  --color-three-dot-outline: var(--color-outline-base);
  --color-three-dot-hover-bg: var(--slightly-darker-body-color-background);
  --color-three-dot-accent-icon-fill: var(--color-text-highlight);
  --color-three-dot-selected-bg: var(--slightly-darker-body-color-background);

  --color-three-dot-neutral-outline: var(--cool-gray-70);
  --color-three-dot-neutral-hover-bg: var(--cool-gray-90);
  --color-three-dot-neutral-accent-icon-fill: var(--cool-gray-40);
  --color-three-dot-neutral-selected-bg: var(--cool-gray-90);

  --color-three-dot-red-icon-fill: var(--color-error);
  --color-three-dot-red-bg: #f8e6e6;
  /* For non-clickable items. Clickable items should use var(--teal-vivid-30) */
  --color-generic-highlight-bg: #f9fafc;

  /* Label */
  --color-label-text: var(--color-text-primary);
  --color-label-border: var(--cool-gray-80);
  --color-label-bg: var(--cool-gray-95);

  /* Input */
  --color-input-bg: var(--cool-gray-95);
  --color-input-bg-hover: #eef0f5;
  --color-input-focused-bg: var(--color-highlight);
  --color-input-border: var(--cool-gray-60);
  --color-input-focused-border: var(--color-accent);
  --color-input-error: var(--color-error);
  --color-input-error-bg: #fff0f0;
  --color-input-focused-label-color: var(--color-accent);
  --input-icon-fill: var(--cool-gray-60);
  --input-focused-icon-fill: var(--color-accent);

  /* Checkbox */
  --color-checkbox-unchecked: var(--color-accent);
  --color-checkbox: var(--color-accent);
  --color-checkbox-checked: var(--color-accent);

  /* Cards */
  --color-card-icon: var(--color-accent);
  --color-card-border: var(--cool-gray-90);
  --color-card-border-hover: var(--teal-vivid-50);
  --color-card-background-hover: var(--color-surface);

  /* Tables */
  --color-table-border: #dae0e7;

  /* Elevation shadows */
  --elevation-very-low: 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
  --elevation-low: 0px 1px 3px rgba(0, 0, 0, 0.1);
  --elevation-medium: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1);
  --elevation-high: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 18px 36px rgba(0, 0, 0, 0.1);

  /* Grid */
  --color-grid-border: var(--cool-gray-80);
  --color-grid-border-light: var(--cool-gray-90);
  --color-grid-header-bg: var(--cool-gray-95);

  /* Scorecard */
  --color-scorecard-value: var(--cool-gray-20);
  --color-scorecard-name: var(--cool-gray-60);
  --color-scorecard-gray-name: var(--cool-gray-40);
  --color-scorecard-gray-bg: var(--cool-gray-95);

  /* Switch */
  --color-switch-checked: var(--color-success-light);
  --color-switch-unchecked: #b0b0b0;

  /* Modal */
  --color-modal-background: white;

  /* Badge (e.g. Beta badge) */
  --color-badge-bg: var(--color-highlight);
  --color-badge-border: var(--teal-vivid-50);
  --color-badge: var(--color-accent);

  /* Misc */
  --color-outline-base: #97ddb8;
  --color-disabled: var(--cool-gray-60);
  --color-divider: var(--cool-gray-90);
  --color-divider-active: var(--color-accent);
  --color-avatar-background: #e56f1a;
  --color-keyboard-combo: var(--color-text-secondary);
  --color-outline: var(--teal-vivid-20);
  --color-popover-border: #dce5e3;
  --color-sub-heading: var(--cool-gray-60);
  --color-spinner: var(--color-accent);
  --color-dashboard-notice-bg: #fffbea;
  --color-dashboard-notice-border: var(--color-warning);
  --color-title-link-hover: var(--green-30);

  -webkit-font-smoothing: antialiased;
  --p-margin: var(--space-s) var(--space-m) var(--space-m) var(--space-m);

  --topnav-height: 0px;
  --input-height: 58px;
  --icon-size-primary: 24px;
  --icon-size-secondary: 18px;
  --icon-size-tertiary: 12px;

  --drawer-size: 430px;

  /* Illustrations */
  --color-illustration-stroke-1: #036457;
  --color-illustration-fill-0: #f5f7fa;
  --color-illustration-fill-1: #d86d21;
  --color-illustration-fill-2: #ffcd00;
  --color-illustration-fill-3: #78be21;
  --color-illustration-fill-4: #036457;
}

.theme-lettuce .em {
  font-weight: bold;
  color: var(--cool-gray-20);
}

.theme-lettuce hr {
  border-top: 1px solid var(--color-divider);
}

/* Required for Storybook MDX docs */
#storybook-docs ol {
  list-style-type: decimal !important;
}

#storybook-docs pre {
  font-size: 1.3rem;
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.resourcesMenuItemsWrapper {
  height: 100vh;
  width: 350px;
  overflow-y: auto;
  padding: var(--space-m);
}

@media (max-width: 768px) {
  .resourcesMenuItemsWrapper {
    width: 100vw;
  }
}

.input {
  font-size: var(--text-m);
  font-weight: 300;
  letter-spacing: 0.02rem;
  padding: 10px;
  width: 100%;
  border: none;
  background: transparent;
  outline-width: 0;
}

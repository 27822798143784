@value scaleFactor: .5;
@value translateDistance: -60px;

.appear,
.enter {
  opacity: 0;
  transform: scale(scaleFactor) translateY(translateDistance);
}

.enter-done {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.exit,
.exitDone {
  opacity: 0;
  transform: scale(scaleFactor) translateY(calc(-1 * translateDistance));
}

.container {
  display: flex;
  justify-content: center;
  padding: var(--space-l);
}

.spinner {
  width: 60px;
  height: 60px;
}

.spinnerSvg {
  animation: rotate 2s linear infinite;
}

.spinnerSvg circle {
  stroke: var(--color-spinner);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 180;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 108, 180;
    stroke-dashoffset: -50;
  }
  100% {
    stroke-dasharray: 108, 180;
    stroke-dashoffset: -158;
  }
}

.card {
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-card-border);
  background-color: var(--color-surface);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
  transition: box-shadow 0.3s ease-in-out;
}

.clickable {
  cursor: pointer;
}

.link:focus-visible {
  outline: none;
}

.link:focus-visible .card {
  outline: 2px solid var(--color-card-border-hover);
  outline-offset: -1px;
}

.clickable:hover, .link:hover, .link:link:hover {
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04), 0 1px 4px rgba(0, 0, 0, 0.04);
}

.disabled {
  opacity: 0.4;
}

.link {
  color: var(--color-text-primary);
  text-decoration: none;
  border-radius: var(--border-radius-card);
}
.link:hover {
  text-decoration: none !important;
}

.link .card {
  color: var(--color-text-primary);
}

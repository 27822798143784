@value colors: '../../../colors.css';
@value textHighlight from colors;

.input {
  height: 48px;
  display: inline-flex;
  position: relative;
  width: 100%;
}

.input > input,
.input > textarea {
  background-color: var(--body-color-background);
  border-radius: 2px;
  border: 1px solid #aaa;
  font-size: var(--text-m);
  font-weight: 300;
  letter-spacing: .02rem;
  padding: 10px;
  width: 100%;
}

input.hasError {
  border: 1px solid red;
  border-bottom: 3px solid red;
}

input.hasIcon {
  padding-right: 34px;
}

input.hasIcon.left {
  padding-left: 36px;
  padding-right: 10px;
}

.input > .icon > svg {
  position: absolute;
  top: 14px;
  right: 14px;
  opacity: 0.5;
  height: 18px;
  width: 18px;
}

.input.left > .icon > svg {
  left: 12px;
}

.inputLabel {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0;
  background-color: textHighlight;
  background-image: none;
  padding: 1em 1em;
  color: rgba(0,0,0,.6);
  text-transform: none;
  font-weight: 400;
  border: 1px solid #aaa;
  border-right: none;
  border-radius: 6px;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;
  flex-shrink: 0;
}

.inputLabel.right {
  border: 1px solid #aaa;
  border-left: none;
  border-radius: 0 6px 6px 0;
}

.inputLabel:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestContainer {
  position: absolute;
  top: 13.5px;
  left: 31px;
  width: 100%;
}

.valueText {
  color: white;
  opacity: 0;
  height: 0;
  z-index: -1;
}

.suggestText {
  color: var(--color-text-subtle);
}

@value z-indices: '../../z-indices.css';
@value stickyOverlay from z-indices;

.container {
  margin: var(--space-l) 0;
  display: flex;
}

.sticky {
  position: sticky;
  z-index: stickyOverlay;
  top: 0;
  background: white;
}

.actionContainer {
  margin-left: auto;
  padding: var(--space-m);
}

.heading,
.subHeading {
  margin-top: 0;
  margin-bottom: 0;
}

.modalHeader {
  margin: var(--space-m) 0;
}

:global(.theme-lettuce) .modalHeader {
  padding-bottom: var(--space-m);
  border-bottom: 1px solid var(--cool-gray-90);
}

.modalHeading {
  font-size: var(--text-xl);
  margin-top: 0;
  margin-bottom: 0;
}

.modalHeadingLinkButton {
  margin-left: var(--space-xs);
  position: relative;
  top: -1px;
}

.subtle {
  color: var(--color-text-subtle);
}

.XS {
  font-size: var(--text-xs);
}

.S {
  font-size: var(--text-s);
}

.M {
  font-size: var(--text-m);
}

.L {
  font-size: var(--text-l);
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 500;
}

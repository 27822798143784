.container {
  position: relative;
}

.avatarImg {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.avatarPlaceholder {
  align-items: center;
  background-color: #6D455A;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.initials {
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}

.header {
  margin: var(--space-l) 0;
}

.subHeading {
  margin-top: 0;
}

.subHeadingWithSideAction {
  margin-top: 7px;
}

@value s: var(--space-s);
@value m: var(--space-m);

.accountDetails {
  padding: s m;
}

.accountName {
  font-size: var(--text-l);
}

.preamble {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: var(--color-text-subtle);
  font-weight: 500;
  margin-bottom: var(--space-s);
}

.link {
  font-size: var(--text-xs);
}

@value z-indices: '../../../z-indices.css';
@value stickyOverlay from z-indices;

@value m: var(--space-m);
@value borderRadius: 8px;

.body {
  position: relative;
  outline: none;
  background-color: var(--color-surface);
  border: 1px solid var(--color-table-border);
  box-shadow: var(--elevation-low);
  border-radius: borderRadius;
  overflow: auto;
}

.body.inline {
  display: inline-block;
}

.table {
  border-collapse: collapse;
  width: 100%;
  outline: none;
}

.table thead {
  position: sticky;
  top: -1px;
  z-index: stickyOverlay;
  border: none;
  background-color: var(--cool-gray-95);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
  padding: 0 m;
  height: 70px;
  font-weight: 600;
  font-size: var(--text-s);
  color: var(--cool-gray-40);
}
.table thead th:first-child {
  border-top-left-radius: borderRadius;
}
.table thead th:last-child {
  border-top-right-radius: borderRadius;
}

.table td,
.table th {
  max-width: 300px;
  padding: 0 m;
}

.table:not(.textWrap) td,
.table:not(.textWrap) th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table td {
  height: 45px;
  padding: m;
  text-overflow: ellipsis;
}

.table th:hover .sortIconContainer svg {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.table tr td { border-style: none; }

.table tbody tr {
  padding: 0 m;
  border-top: 1px solid var(--cool-gray-90);
}

.sortIconContainer {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 var(--space-s);
}
.sortIconContainer svg {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.sortIconSorted svg {
  opacity: 1;
}

/* Required for eslint */
.textWrap {
}

.table:not(.textWrap) td,
.table:not(.textWrap) th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600&family=Roboto+Mono:ital,wght@0,400;0,600;1,200;1,500&display=swap');
@import url('themes/lettuce.css');
@import url('themes/unfi.css');
@import url('themes/rema.css');
@import url('themes/advantage.css');
@import url('themes/highImpact.css');
@import url('themes/dot.css');
@import url('themes/instacart.css');

/* Legacy Theme CSS. Will be removed when all pages are fully migrated to Lettuce or any other theme. */
:root {
  --font-primary: 'Roboto', sans-serif;
  --body-color-background: white;

  /* Spacing */
  --space-xs: 5px;
  --space-s: 10px;
  --space-m: 20px;
  --space-l: 40px;
  --space-xl: 60px;

  --gap-s: var(--space-s);
  --gap-m: var(--space-m);

  --text-xxs: 1rem;
  --text-xs: 1.3rem;
  --text-s: 1.4rem;
  --text-m: 1.6rem;
  --text-l: 1.9rem;
  --text-xl: 2.5rem;
  --text-xxl: 3.2rem;

  /* Colors */
  --color-crisp-green: #00807f;
  --color-primary: var(--color-crisp-green);
  --color-kale: #005257;
  --color-leaf: #00bb7e;
  --color-cypress: #003a3f;
  --color-mint: #d1f1e6;
  --color-teal: #007672;
  --color-steak-red: #cb333b;
  --color-ube-purple: #8b84d7;
  --color-pale-green-highlight: #e0f0ef;
  --color-pale-green-highlight-translucent: #00807f1f;
  --color-accent: var(--color-primary);

  /* Brand colors */
  --color-apple: #78be21;
  --color-citrus: #ffa400;
  --color-rare: #dc8699;
  --color-plum: #7c2855;
  --color-tangerine: #d86d21;
  --color-blueberry: #006ba6;
  --color-banana: #ffcd00;
  --color-steak: #cb333b;
  --color-sorbet: #caa2dd;
  --color-spearmint: #40c1ac;
  --color-water: #71c5e8;
  --color-pear: #c4d600;
  --color-salmon: #e8927c;
  --color-ube: #8b84d7;

  --color-dark-blue: hsl(201, 100%, 33%);
  --color-dark-orange: hsl(25, 73%, 49%);
  --color-dark-green: hsl(87, 81%, 35%);
  --color-dark-red: hsl(357, 60%, 50%);
  --color-dark-purple: hsl(328, 51%, 32%);

  --color-dark-blue-hover: hsl(201, 100%, 23%);
  --color-dark-orange-hover: hsl(25, 73%, 39%);
  --color-dark-green-hover: hsl(87, 81%, 25%);
  --color-dark-red-hover: hsl(357, 60%, 40%);
  --color-dark-purple-hover: hsl(328, 51%, 22%);

  /* Colors text */
  --color-text-primary: #393939;
  --color-text-secondary: #5b5b5b;
  --color-text-subtle: #767676;
  --color-text-link: var(--color-primary);
  --color-text-heading: var(--color-text-primary);

  /* Colors lists */
  --color-list-item-bg-focused: var(--color-pale-green-highlight);
  --color-list-item-selected: var(--color-kale);
  --color-list-item-bg-soft-focus: #f2f2f2;

  /* Colors borders */
  --color-container-border: #ebebeb;

  /* Colors tables */
  --color-table-header-border: #ddd;
  --color-table-alternating-row: #f4f4f4;

  /* Colors misc */
  --color-outline: var(--color-primary);
  --color-button-outline: var(--color-outline);
  --color-field-error: #d93900;
  --color-menu-highlight-translucent: var(--color-pale-green-highlight-translucent);
  --color-progressbar-fill: var(--color-leaf);
  --color-progressbar-background: #003a3f1f;

  /* Colors backgrounds */
  --color-background-disabled: #f5f5f5;
  --color-background-positive: #daf2d9;
  --color-background-warning: #ffd7a5;
  --color-background-neutral: #e4eaf4;
  --color-background-text-highlight: #e8e8e8;

  /* Colors input */
  --color-checkbox-unchecked: #7c8a8a;
  --color-checkbox-checked: var(--color-primary);

  /* Border radius */
  --border-radius-container: 6px;
  --border-radius-button: 4px;
  --border-radius-card: 8px;

  --topnav-height: 60px;

  --color-keyboard-combo: #5c5c5c;

  /* Colors used on the data catalog pages but also some shared components
     (which cannot access these if they are not loaded with the main application).
     These should be merged with the above when we introduce Lettuce.
     The CSS variables are part of the Lettuce theme which is a shared entity.
  */
  --docs-color-crisp-green: #00807f;
  --docs-color-crisp-kale: #005257;
  --docs-color-primary: #004f59;
  --docs-color-pale-green-translucent: #00807f17;
  --docs-color-pale-green: #e8f4f3;
  --docs-color-crisp-tangerine: #d86d21;
  --docs-hover-overlay: rgba(0, 0, 0, 0.05);
  --docs-text-color-primary: #5b5b5b;
  --docs-text-color-subtle: #929292;

  --docs-font-semibold: 500;
  --docs-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;

  --docs-section-color-separator: #ededed;

  --docs-menu-color-hover-background: var(--docs-hover-overlay);
  --docs-menu-color-selected-background: var(--docs-color-pale-green-translucent);
  --docs-menu-color-active: var(--docs-color-primary);
  --docs-menu-color-active-outline: var(--docs-color-crisp-green);

  --docs-table-th-color-background: #f7f7f8;
  --docs-table-color-border: #ebebeb;
  --docs-table-cell-padding: 16px var(--space-s);
  --docs-table-stripe-background: #d5d5d5;
  --docs-table-color-focus-border: var(--docs-color-crisp-green);
  --docs-table-color-focus-background: var(--docs-color-pale-green-translucent);
  --docs-table-color-pending-background: rgba(255, 233, 7, 0.1);
  --docs-table-color-error-background: rgba(255, 23, 77, 0.1);

  --docs-code-color-background: rgb(246, 247, 248);
  --docs-code-font-size: var(--text-s);

  --docs-icon-color-neutral: #7b8685;
  --docs-icon-color-active: var(--docs-color-primary);
  --docs-icon-color-inactive: var(--docs-icon-color-neutral);

  --docs-table-cell-focus-active-box-shadow: 0 0 0 3px var(--docs-table-color-focus-border);
  --docs-table-cell-focus-inactive-box-shadow: 0 0 0 3px #d6d6d6;

  --docs-icon-button-color-svg-stroke: #ebe8e3;
  --docs-icon-button-color-svg-fill: #797368;
  --docs-icon-button-color-hover-svg-fill: #59544b;
  --docs-icon-button-color-border: var(--docs-icon-button-color-svg-stroke);

  --p-margin: var(--space-m);

  /* Chips */
  --color-chip-bg: hsl(0, 0%, 89%);
  --color-chip-border: transparent;
  --color-chip-hover-bg: hsl(0, 0%, 83%);
  --color-chip-hover-border: transparent;
  --color-chip-selected-border: transparent;
  --color-chip-selected-bg: var(--cool-gray-40);
  --color-chip-selected: white;
  --color-chip-icon-fill-dark: var(--color-text-primary);
  --color-chip-icon-fill-medium: var(--color-text-primary);

  /* List */
  --padding-list-item: var(--space-s) var(--space-m);
  --margin-list-item: 0;

  /* Menu */
  --color-menu-item-bg-focused: var(--docs-color-pale-green);

  /* Cards */
  --color-card-icon: var(--color-teal);
  --color-card-border: #ebe8e3;
  --color-card-border-hover: #bbbbbb;
  --color-card-background-hover: #fafafa;

  /*  Misc */
  --color-divider: #ebebeb;
  --color-divider-active: var(--color-primary);
  --input-height: 48px;

  /* Modal */
  --color-modal-background: white;
}

html {
  box-sizing: border-box;
  height: 100%;
  letter-spacing: 0.02rem;
  line-height: 1.3;
  font-size: 62.5%;
  font-weight: normal;
}

body {
  background-color: var(--body-color-background);
  color: var(--color-text-primary);
  font-family: var(--font-primary);
  font-size: var(--text-m);
  min-height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: #393939;
  font-family: var(--font-primary);
  font-weight: normal;
  font-size: 1.6rem;
  letter-spacing: 0.02rem;
  line-height: 1.15;
  outline-color: var(--color-button-outline);
}

input:not([type='radio']):disabled,
select:disabled,
textarea:disabled {
  background-color: var(--color-background-disabled) !important;
}

img {
  max-width: 100%;
}
/* .mod = Base styling module */
.mod,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
pre,
center,
dl {
  margin: var(--space-m);
}

p {
  margin: var(--p-margin);
}

hr {
  border: none;
  border-top: 1px solid #ebe8e3;
  clear: both;
  margin: var(--space-m);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text-heading);
}

h1,
.h1 {
  font-size: 2.55rem;
  font-weight: 500;
  letter-spacing: -0.05rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3.2rem;
  }
}

h2,
.h2 {
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.02rem;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}

h3,
.h3 {
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.02rem;
  line-height: 1.2;
}

ul,
ol,
nav {
  list-style-type: none;
  padding: 0;
}

dt {
  color: #737373;
}

dd {
  margin: 0;
}

a,
a:link,
a:visited {
  color: var(--color-text-link);
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
}
a:hover,
a:link:hover {
  text-decoration: underline;
}

body.theme-lettuce a {
  outline-color: var(--color-outline-base);
}

strong {
  font-weight: 700;
}

iframe {
  border: 0;
}

/* Spacing
 *
 * Naming scheme:
 *
 * First letter: type
 *  - m: margin
 *  - p: padding
 *
 * Second letter: direction
 *  - t: top
 *  - r: right
 *  - b: bottom
 *  - l: left
 *  - h: horizontal
 *  - v: vertical
 *  - a: all
 *
 * Third letter: size
 *  -  l: large
 *  -  m: medium
 *  -  s: small
 *  - xs: extra small
 *  -  n: none
 *
 * Examples:
 *  - mtn: margin top none
 *  - pvs: padding vertical small
 */
.man,
.mhn,
.mln {
  margin-left: 0 !important;
}
.man,
.mhn,
.mrn {
  margin-right: 0 !important;
}
.man,
.mvn,
.mtn {
  margin-top: 0 !important;
}
.man,
.mvn,
.mbn {
  margin-bottom: 0 !important;
}

.mas,
.mhs,
.mls {
  margin-left: var(--space-s) !important;
}
.mas,
.mhs,
.mrs {
  margin-right: var(--space-s) !important;
}
.mas,
.mvs,
.mts {
  margin-top: var(--space-s) !important;
}
.mas,
.mvs,
.mbs {
  margin-bottom: var(--space-s) !important;
}

.mam,
.mhm,
.mlm {
  margin-left: var(--space-m) !important;
}
.mam,
.mhm,
.mrm {
  margin-right: var(--space-m) !important;
}
.mam,
.mvm,
.mtm {
  margin-top: var(--space-m) !important;
}
.mam,
.mvm,
.mbm {
  margin-bottom: var(--space-m) !important;
}

.mal,
.mhl,
.mll {
  margin-left: var(--space-l) !important;
}
.mal,
.mhl,
.mrl {
  margin-right: var(--space-l) !important;
}
.mal,
.mvl,
.mtl {
  margin-top: var(--space-l) !important;
}
.mal,
.mvl,
.mbl {
  margin-bottom: var(--space-l) !important;
}

.pan,
.phn,
.pln {
  padding-left: 0 !important;
}
.pan,
.phn,
.prn {
  padding-right: 0 !important;
}
.pan,
.pvn,
.ptn {
  padding-top: 0 !important;
}
.pan,
.pvn,
.pbn {
  padding-bottom: 0 !important;
}

.pas,
.phs,
.pls {
  padding-left: var(--space-s) !important;
}
.pas,
.phs,
.prs {
  padding-right: var(--space-s) !important;
}
.pas,
.pvs,
.pts {
  padding-top: var(--space-s) !important;
}
.pas,
.pvs,
.pbs {
  padding-bottom: var(--space-s) !important;
}

.pam,
.phm,
.plm {
  padding-left: var(--space-m) !important;
}
.pam,
.phm,
.prm {
  padding-right: var(--space-m) !important;
}
.pam,
.pvm,
.ptm {
  padding-top: var(--space-m) !important;
}
.pam,
.pvm,
.pbm {
  padding-bottom: var(--space-m) !important;
}

.pal,
.phl,
.pll {
  padding-left: var(--space-l) !important;
}
.pal,
.phl,
.prl {
  padding-right: var(--space-l) !important;
}
.pal,
.pvl,
.ptl {
  padding-top: var(--space-l) !important;
}
.pal,
.pvl,
.pbl {
  padding-bottom: var(--space-l) !important;
}

.mha {
  margin: 0 auto;
}

/* Normalize search input for Safari */
input[type='search'] {
  -webkit-appearance: textfield;
}
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Custom style for radio input */
body:not(.theme-lettuce) input[type='radio'] {
  --radioCircleSize: 14px;
  --radioCircleBorderSize: 2px;
  --radioCheckedCircleSize: calc(var(--radioCircleSize) - 2 * var(--radioCircleBorderSize));
  --radioCheckedCircleOffset: calc(
    (var(--radioCircleSize) - var(--radioCheckedCircleSize)) / 2 + var(--radioCircleBorderSize)
  );
  --radioCircleBorderColor: #737373;
  --disabledColor: #d9d9d9;

  /* Necessary for iOS */
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  position: relative;
  top: 2px;
}

body:not(.theme-lettuce) input[type='radio']::before {
  -webkit-appearance: none;
  -moz-appearance: none;
  content: '';
  display: block;
  border-radius: 50%;
  border: var(--radioCircleBorderSize) solid var(--radioCircleBorderColor);
  width: var(--radioCircleSize);
  height: var(--radioCircleSize);
}

body:not(.theme-lettuce) input[type='radio']:focus::before {
  box-shadow: 0 0 0 calc(2 * var(--radioCircleBorderSize)) rgba(0, 130, 128, 0.3);
}

body:not(.theme-lettuce) input[type='radio']:checked::after {
  content: '';
  display: block;
  position: absolute;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: var(--radioCheckedCircleSize);
  height: var(--radioCheckedCircleSize);
  top: var(--radioCheckedCircleOffset);
  left: var(--radioCheckedCircleOffset);
}

body:not(.theme-lettuce) input[type='radio']:disabled::before {
  border-color: var(--disabledColor);
}

body:not(.theme-lettuce) input[type='radio']:checked:disabled::after {
  background-color: var(--disabledColor);
}
/* End radio input */

/* Misc utility classes */
.relativePosition {
  position: relative;
}

svg.tooltipIcon g {
  pointer-events: none;
}

.markdown ol {
  list-style-type: decimal;
  padding: 0 20px;
}

.markdown ul {
  list-style-type: inherit;
  padding: 0 20px;
}

/* Use this as a sub-heading to avoid including html in markdown */
.markdown h4 {
  margin-top: -10px;
  color: #929292;
  font-weight: normal;
}

/*
	Improved screen reader only CSS class
  https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
	@author Gaël Poupard
		@note Based on Yahoo!'s technique
		@author Thierry Koblentz
		@see https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
	* 1.
		@note `clip` is deprecated but works everywhere
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip
	* 2.
		@note `clip-path` is the future-proof version, but not very well supported yet
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
		@see http://caniuse.com/#search=clip-path
		@author Yvain Liechti
		@see https://twitter.com/ryuran78/status/778943389819604992
	* 3.
		@note preventing text to be condensed
		author J. Renée Beach
		@see https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
		@note Drupal 8 goes with word-wrap: normal instead
		@see https://www.drupal.org/node/2045151
		@see http://cgit.drupalcode.org/drupal/commit/?id=5b847ea
	* 4.
		@note !important is important
		@note Obviously you wanna hide something
		@author Harry Roberts
		@see https://csswizardry.com/2016/05/the-importance-of-important/
*/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

/* Disable pointer events for content inside a button. Prevents tooltip dismiss when hovering over the content. */
button > * {
  pointer-events: none;
}

nav > ul {
  padding: 0;
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-decoration {
  text-decoration: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.displayNone {
  display: none;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* "input" is necessary here to ensure this style always overwrites that of BasicInput, regardless
   of the order in which the stylesheets are added to the DOM.
   (See https://stackoverflow.com/a/58400612/16109045 for a clear description of the problem).
 */
input.renderInput {
  background-color: var(--body-color-background);
  border-radius: 2px;
  border: 1px solid #aaa;
  outline-width: initial;
}

@value size: 48px;

.input {
  height: size;
  display: inline-flex;
  position: relative;
  width: 100%;
}

/* This should NOT be overwritten. */
input.hasError {
  border: 1px solid var(--color-field-error);
}

/* Input with IconButton */

.paddingForIcon {
  padding-right: size;
}

.iconButton {
  position: absolute;
  right: 2px;
  top: 2px;
  width: calc(size - 4px);
  height: calc(size - 4px);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
}

.iconButton:hover {
  background-color: #efefef;
}

.iconButton.toggled {
  background-color: #e3f0ef;
}

.iconButton svg {
  width: 24px;
  height: 24px;
  fill: var(--color-text-secondary);
}

.iconButton.toggled svg {
  fill: var(--color-primary);
}

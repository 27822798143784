@value sizeS: 32px;
@value sizeM: 48px;
@value sizeL: 64px;

.container {
  width: sizeM;
  height: sizeM;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #d9e2ec;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.L {
}

.svgStretch {
}

.container:not(.svgStretch) svg {
  width: 18px;
  fill: var(--color-text-primary);
}

.container.L:not(.svgStretch) svg {
  width: 36px;
}

.container.S {
  width: sizeS;
  height: sizeS;
}

.container.L {
  width: sizeL;
  height: sizeL;
}

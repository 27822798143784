.supportingText {
  font-size: var(--text-s);
  padding: var(--space-s) var(--space-m);
}

.supportingText.error {
  color: var(--color-input-error);
}

.hasMarkdownDescription > p {
  margin: 0;
}

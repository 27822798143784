.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-s) var(--space-m);
  background-color: var(--color-accent);
}

.container.s {
  padding: var(--space-xs) var(--space-m);
}

.lhs {
  font-size: var(--text-s);
  color: white;
}

.rhs {
  color: #e6ffff;
  font-size: var(--text-xs);
}

@value colors: '../../../colors.css';
@value link from colors;

.searchInput {
  border-bottom: 2px solid #aaa !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  outline: none !important;
  border-radius: 0 !important;
  padding: 5px 25px 7px 3px !important;
  height: auto !important;
}

.searchInput:focus {
  border-color: link !important;
}

.subheading {
  text-align: center;
  margin-bottom: var(--space-l);
}

.logo {
  width: 175px;
}

.securityContainer {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  min-width: 410px;
}

.logoContainer {
  background: var(--teal-vivid-20);
  flex-grow: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-m);
}

.logoContainer svg {
  height: 60px;
  width: auto;
}

.rhs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 7;
  flex-basis: min-content;
}

.authPanel {
  width: 50%;
  min-width: 360px;
  max-width: 410px;
}

.compact {
  flex-direction: column;
  justify-content: flex-start;
}

.compact .rhs {
  justify-content: flex-start;
}

.compact .logoContainer {
  background: transparent;
}

/* Add more sizes as required */
@value sizeM: 30px;
@value sizeL: 40px;

.avatar {
  width: sizeM;
  height: sizeM;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-avatar-background);
}

.avatar.L {
  width: sizeL;
  height: sizeL;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.avatar span {
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  font-size: calc(sizeM / 2);
}

.avatar.L span {
  font-size: var(--text-m);
}

.transparent {
  background-color: transparent;
}

.apple {
  background-color: var(--color-apple);
}

.citrus {
  background-color: var(--color-citrus);
}

.rare {
  background-color: var(--color-rare);
}

.plum {
  background-color: var(--color-plum);
}

.tangerine {
  background-color: var(--color-tangerine);
}

.blueberry {
  background-color: var(--color-blueberry);
}

.banana {
  background-color: var(--color-banana);
}

.steak {
  background-color: var(--color-steak);
}

.sorbet {
  background-color: var(--color-sorbet);
}

.spearmint {
  background-color: var(--color-spearmint);
}

.water {
  background-color: var(--color-water);
}

.pear {
  background-color: var(--color-pear);
}

.salmon {
  background-color: var(--color-salmon);
}

.ube {
  background-color: var(--color-ube);
}

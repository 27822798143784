body.theme-instacart {
  --color-primary: #108910;

  /*--color-primary-hover: #129112;*/
  /*--color-primary-2: #0a6b0a;*/
  /*--color-primary-3: #054d05;*/

  --color-accent: var(--color-primary);
  --color-highlight: #f0fff0;
  --color-text-highlight: var(--color-secondary);
  --color-text-link: var(--color-accent);

  /*--color-outline-base: var(--blue-vivid-100);*/

  /* Buttons */
  --color-button-outline: var(--color-outline-base);
  --color-button-primary: white;
  --color-button-primary-bg: var(--color-accent);
  --color-button-primary-border: var(--color-accent);
  --color-button-primary-hover-bg: #129112;
  --color-button-primary-hover-border: #129112;
  --color-button-primary-active-border: #129112;
  --color-button-primary-disabled-bg: var(--cool-gray-60);

  --color-button-secondary: var(--color-accent);
  --color-button-secondary-bg: white;
  --color-button-secondary-border: var(--cool-gray-80);
  --color-button-secondary-hover-bg: var(--color-highlight);
  --color-button-secondary-hover-border: var(--color-accent);
  --color-button-secondary-active-bg: white;
  --color-button-secondary-active-border: var(--color-accent);
  --color-button-secondary-disabled: var(--cool-gray-60);
  --color-button-secondary-disabled-border: var(--cool-gray-80);

  --color-button-text: var(--color-primary);
  --color-button-text-hover: var(--color-primary);

  /* Navrail */
  --navrail-color-background: #003D29;
  --navrail-menu-item-color-icon: white;
  --navrail-menu-item-color-icon-hover: white;
  --navrail-menu-item-color-hover: var(--color-accent);
  --navrail-menu-item-label-color: var(--color-accent);
  --navrail-menu-item-label-color-hover: white;
  --navrail-menu-item-selected-color-icon: white;
  --navrail-menu-item-selected-label-color: white;

  /* Three dot menu */
  --color-three-dot-icon-fill: var(--cool-gray-60);
  --color-three-dot-outline: var(--color-outline-base);
  --color-three-dot-hover-bg: var(--color-primary-80);
  --color-three-dot-accent-icon-fill: var(--color-accent);
  --color-three-dot-selected-bg: var(--color-primary-80);
}

.container {
  display: flex;
  align-items: stretch;
  min-height: 300px;
}

.sections {
  padding: var(--space-s);
  flex-shrink: 0;
  flex-grow: 0;
  width: 140px;
  border-right: 1px solid var(--color-divider);
}

.sections ul {
  margin: 0;
  padding: 0;
}
.selectSection {
  background: transparent;
  display: block;
  text-align: left;
  width: 100%;
  cursor: pointer;
  border: 2px solid transparent;
  color: var(--color-text-primary);
  padding: var(--space-s);
  border-radius: var(--border-radius-container);
  font-size: var(--text-s);
  font-weight: 500;
}

.selectSection:hover {
  background-color: var(--color-menu-item-bg-focused);
  color: var(--color-menu-item-focused);
  border: 2px solid var(--color-accent);
}

.selectSection.active:not(:hover) {
  background-color: var(--color-menu-item-bg-selected);
  color: var(--color-menu-item-selected);
}

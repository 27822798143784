.gap-S {
  gap: var(--space-s);
}

.gap-M {
  gap: var(--space-m);
}

.gap-L {
  gap: var(--space-l);
}

.rowGap-S {
  row-gap: var(--space-s);
}

.rowGap-M {
  row-gap: var(--space-m);
}

.rowGap-L {
  row-gap: var(--space-l);
}

.container {
  display: flex;
  justify-content: center;
}

.panel {
  margin-top: 100px;
  min-width: 450px;
  max-width: 500px;
  padding: var(--space-xl) var(--space-m);
}

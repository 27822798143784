.panel {
  min-width: 300px;
  background-color: var(--color-surface);
  overflow: auto;
  border-radius: var(--border-radius-panel);
  border: 1px solid var(--color-panel-neutral-border);
}

.panel.decorated {
  border-radius: 2px;
  min-height: 200px;
  border: none;
  border-top: 6px solid var(--color-panel-border);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.16)) drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.08));
}

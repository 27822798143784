@value colors: '../../../../colors.css';
@value default, border, selectedListOption, subtle, focusListOption from colors;

.option {
  cursor: pointer;
  margin: 0;
  white-space: nowrap;
  display: block;
  color: default;
  padding: var(--space-s) var(--space-m);
  overflow: hidden;
  border-width: 1px 0;
  position: relative;
}

.option.minWidthStandard {
  min-width: 200px;
}

.option:hover {
  background-color: var(--color-list-item-bg-focused);
}

.option.borderFocus:focus,
.option.borderFocus.focused:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-top: 1px solid var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  pointer-events: none;
}

/* Hover background color takes precedence (explicit rule) */
.option.backgroundFocus:not(:hover):focus,
.option.backgroundFocus.focused:not(:hover) {
  background-color: focusListOption;
}

.option.omitTopBorder:first-child:focus,
.option.focused.omitTopBorder:first-child:after {
  border-top: none;
}

.option.omitBottomBorder:last-child:focus,
.option.focused.omitBottomBorder:last-child:after {
  border-bottom: none;
}

.option:not(.backgroundFocus)[aria-selected='true'] {
  background-color: selectedListOption;
}

.selectedOption {
  font-weight: 700;
}

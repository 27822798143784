@value z-indices: '../../../z-indices.css';
@value tooltipColors: './tooltipColors.css';

@value s: var(--space-s);

@value backgroundColor, textColor from tooltipColors;
@value borderRadius: 4px;
@value arrowSize: 8px;
@value arrowMargin: calc(s + s / 2);
@value arrowOffset: calc(arrowMargin - arrowSize);
@value tooltip as tooltipZIndex from z-indices;

.popper {
  z-index: tooltipZIndex;
}

.popper > .tooltipContent {
  color: textColor;
  background-color: backgroundColor;
  padding: var(--space-s);
  border-radius: borderRadius;
  font-size: var(--text-s);
  pointer-events: none;
}

.popper[data-popper-placement^='bottom'] > .tooltipContent {
  margin-top: arrowMargin;
}

.popper[data-popper-placement^='right'] > .tooltipContent {
  margin-left: arrowMargin;
}

.popper[data-popper-placement^='left'] > .tooltipContent {
  margin-right: arrowMargin;
}

.popper[data-popper-placement^='top'] > .tooltipContent {
  margin-bottom: arrowMargin;
}

.popper .arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-width: arrowSize;
  border-style: solid;
  border-color: transparent;
}

.popper[data-popper-placement^='bottom'] > .arrow {
  top: arrowOffset;
  border-top-width: 0;
  border-bottom-color: backgroundColor;
}

.popper[data-popper-placement^='top'] > .arrow {
  bottom: arrowOffset;
  border-bottom-width: 0;
  border-top-color: backgroundColor;
}

.popper[data-popper-placement^='right'] > .arrow {
  left: arrowOffset;
  border-left-width: 0;
  border-right-color: backgroundColor;
}

.popper[data-popper-placement^='left'] > .arrow {
  right: arrowOffset;
  border-right-width: 0;
  border-left-color: backgroundColor;
}

.tooltipContent {
  max-width: 300px;
}

.label {
  color: var(--color-label-text);
  background-color: var(--color-label-bg);
  font-weight: 500;
  font-size: var(--text-xs);
  border: 1px solid var(--color-label-border);
  padding: 2px 6px;
  border-radius: var(--border-radius-primary);
  line-height: 1.6rem;
  white-space: nowrap;
}

.label.red {
  border-color: var(--color-error);
  color: var(--color-error);
  background-color: transparent;
  text-transform: uppercase;
}

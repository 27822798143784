.searchContainer {
  display: flex;
  align-items: center;
  gap: var(--space-s);
  padding: var(--space-m);
}

.searchContainer:focus-within {
  background-color: var(--color-input-focused-bg);
}

.icon {
  width: var(--icon-size-secondary);
  height: var(--icon-size-secondary);
  pointer-events: none;
}

.searchContainer svg {
  fill: var(--color-accent);
}

.searchContainer:focus-within .icon svg {
  fill: var(--color-accent);
}

.searchElement {
  flex-grow: 1;
}

/* Required for draft.js which uses a custom placeholder element */
.searchElement :global(.public-DraftEditorPlaceholder-root) {
  color: #757575;
}

.searchInput {
  width: 100%;
  font-size: var(--space-m);
  background-color: transparent;
  border: none;
  outline: none;
}

.clearSearch {
  display: block;
  border: none;
  background: none;
  cursor: pointer;
  width: var(--icon-size-secondary);
  height: var(--icon-size-secondary);
  padding: 0;
}

.clearSearch:hover svg {
  fill: var(--color-button-text);
}

@value colors: '../../colors.css';
@value border, borderHover, subtle, default as defaultColor from colors;

@value toastWidth: 350px;
@value toastHeight: 80px;
@value shadowColor: rgba(199, 199, 199, 0.5);
@value borderRadius: 4px;
@value highlightSectionWidth: 40px;

@value m: var(--space-m);
@value s: var(--space-s);

.toast {
  position: relative;
  width: toastWidth;
  height: toastHeight;
  border: 1px solid border;
  background-color: white;
  border-radius: borderRadius;
  margin-top: m;
  padding: m s m m;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 3px 0 shadowColor;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  overflow: hidden;
}

.toast:hover {
  border-color: borderHover;
}

.toast:first-child {
  margin-top: 0;
}

.highlightSection {
  position: absolute;
  top: 0;
  left: 0;
  width: highlightSectionWidth;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: borderRadius;
  border-bottom-left-radius: borderRadius;
}

.iconSection {
  width: 18px;
  margin-right: m;
  flex: 0 0 auto;
}

.iconSection svg {
  fill: #393939;
}

.contentSection {
  flex-grow: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.highlightSection + .contentSection {
  margin-left: calc(highlightSectionWidth - s);
}

.dismissSection {
  width: 36px;
  margin-left: s;
  padding: s;
  flex: 0 0 auto;
  border: 0;
  background: transparent;
}

.dismissSection svg {
  pointer-events: none;
  position: relative;
  top: 2px;
  fill: subtle;
}

.toast:hover .dismissSection svg {
  fill: defaultColor;
}

.dismissSection.active {
  cursor: pointer;
}

.body {
  align-items: center;
  display: flex;
  white-space: pre-wrap;
}

.icon {
  align-self: flex-start;
  margin-right: 15px;
  margin-top: 5px;
}

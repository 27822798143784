@value headerHeight: 80px;

.analyticsMenu {
  width: 75vw;
  min-width: 600px;
  height: 100vh;
}

.header {
  height: headerHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.view {
  overflow: auto;
  height: calc(100vh - headerHeight);
  padding-bottom: var(--space-m);
}

.analyticsTabs {
  transition: opacity 0.2s ease-in;
  height: var(--space-l);
}

.searchActive {
  opacity: 0;
}
.searchActive button:hover {
  cursor: unset;
}

.spacer {
  width: 16%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);

}

.radioGroup.row {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-s) var(--space-m);
}

.hasDivider {
  border-bottom: 1px solid var(--color-divider);
}

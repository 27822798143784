.header {
  display: flex;
  align-items: center;
}

.icon {
  fill: var(--teal-vivid-30);
  margin-right: var(--gap-m);
  display: flex;
}

.icon svg {
  height: 32px;
  width: 32px;
}

.itemCount {
  color: var(--color-text-subtle);
  margin-left: var(--space-s);
}

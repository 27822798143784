.separator {
  display: flex;
  align-items: center;
  margin: var(--space-m);
}
.separator:before,
.separator:after {
  content: '';
  flex-grow: 1;
  background: var(--color-divider);
  height: 1px;
  font-size: 0;
  line-height: 0;
}

.separator:before {
  margin-right: var(--space-m);
}

.separator:after {
  margin-left: var(--space-m);
}

.separator:empty:before,
.separator:empty:after {
  margin: 0;
}
